import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import i18next from "../../../i18n";
import api from "../../../api";


export function Newpass() {
  const [loading, setLoading] = useState(true);
  const [irrors, seterror] = useState("");
  const [redirectToEditpass, setRedirectToEditpass] = useState<boolean>();
  const [cin] = useState(sessionStorage.getItem('cin'));
  const [date_naissance] = useState(sessionStorage.getItem('date_naissance'));
  const [codeauth] = useState(sessionStorage.getItem('codeauth'));
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");


  useEffect(() => {

  }, []);

  const validatePassword = () => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (password !== confirmpassword) {
      seterror("Les mots de passe ne correspondent pas.");
      setLoading(true);
      return false;
    }

    if (!passwordPattern.test(password)) {
      seterror("Le mot de passe ne respecte pas le modèle requis.");
      setLoading(true);
      return false;
    }
    return true;
  };


  const handleNewpass = () => {

    const formData = new FormData();
    if (cin !== null) {
      formData.append('cin', cin);
    }
    if (date_naissance !== null) {
      formData.append('date_naissance', date_naissance);
    }
    if (codeauth !== null) {
      formData.append('auth_code', codeauth);
    }
    formData.append('new_password', password);

    api.post('/auth_mobile/new-password', formData
    )
      .then((response) => {
        if (response.status === 200) {
          if (validatePassword()) { 
            console.log("done")
            seterror('Réinitialisation du mot de passe réussie. Veuillez utiliser le nouveau mot de passe pour vous connecter.');
            setLoading(true);
            setTimeout(() => {
              setRedirectToEditpass(true);
            }, 2000);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          seterror('INCORRECT_AUTH_CODE');
        }
        else if(error.response && error.response.status === 400) {
          seterror('PATIENT NOT FOUND');
        }
        setLoading(true);
      });

  };

  return (

    <div className="d-flex flex-column flex-lg-row-fluid">
      {/* Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid">
        {/* Wrapper */}
        <div className="w-lg-500px p-10 p-lg-15 mx-auto">
          {/* Form */}
          <form
            className="form w-100"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(false);
              handleNewpass();
            }}

            id="kt_login_signin_form"
          >

            {/* Heading */}
            <div className="mb-15">
              <h1 className="text-dark mb-3 display-6">
                Nouveau mot de passe
              </h1>
              {/* Link */}
              <div className="text-gray-400 fw-semibold fs-4">

              </div>
            </div>
            {/* Heading */}
            {/* Input group */}
            {/* {irrors ? (
              <div className="mb-lg-15 alert alert-danger ">
                <div className="alert-text fs-7 font-weight-bold  ">{irrors}</div>
              </div>
            ) : ""} */}


            {irrors === "Réinitialisation du mot de passe réussie. Veuillez utiliser le nouveau mot de passe pour vous connecter." ? (
              <div className="mb-lg-15 alert alert-success">
                <div className="alert-text font-weight-bold">{irrors}</div>
              </div>
            ) : (irrors === "INCORRECT_AUTH_CODE" || irrors === "PATIENT NOT FOUND"  || irrors === "Le mot de passe ne respecte pas le modèle requis." || irrors === "Les mots de passe ne correspondent pas."? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">{irrors}</div>
              </div>
            ) : (
              <></>
            ))}

            <div className="fv-row mb-10">
              {/* Label */}
              <label className="form-label fs-6 fw-bold text-dark">Entrez un nouveau mot de passe</label>
              {/* Input */}
              <input className="form-control form-control-lg form-control-solid" type="password" name="cin" autoComplete="off" onChange={(e) => { setpassword(e.target.value) }} />
            </div>
            {/* Input group */}
            {/* Input group */}
            <div className="fv-row mb-10">
              {/* Wrapper */}
              <div className="d-flex flex-stack mb-2">
                {/* Label */}
                <label className="form-label fw-bold text-dark fs-6">Confirmez le mot de passe</label>
              </div>
              {/* Input */}
              <input className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" onChange={(e) => { setconfirmpassword(e.target.value) }} />
            </div>

            {/* Input group */}
            {/* Actions */}
            <div className="">
              {/* Submit button */}
              <button type="submit" id="kt_sign_in_submit" className="btn gradient fw-bolder fs-6 px-8 py-4 my-3 me-3">
                {loading ? <span className="indicator-label text-white">Envoyer</span> : <span className="indicator-progress text-white" style={{ display: "block" }}>
                  {i18next.t('wait')}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>}
              </button>
              {redirectToEditpass && <Redirect to="/auth/login" />}
            </div>
            {/* Actions */}
          </form>
          {/* Form */}
        </div>
        {/* Wrapper */}
      </div>
      {/* Content */}

    </div >


  );
}

import React from "react";
import { PageTitle } from '../../_start/layout/core';


export function Reclamation() {

    return (
        <div>
            <PageTitle>
                Réclamations
            </PageTitle>
           
        </div>
    );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import api from "../../../../app/api";

export function HeaderUserMenu() {

  const handlesignout = () => {
    sessionStorage.clear();
    window.location.reload();
  }


  // const [data,setdata]=useState(sessionStorage.getItem('userdata'));



  // const sexe = data ? JSON.parse(data).sexe : '';
  // const profileImage = getProfileImage(sexe);
  useEffect(() => {
    handleHome();
  }, []);
  const [nom, setnom] = useState('');
  const [prenom, setprenom] = useState('');
  const [cin, setcin] = useState('');
  const [sexe, setsexe] = useState('');

  const handleHome = () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      console.log(token);
    }
    else {
      console.log("nnnn");
    }
    api.get('/auth_mobile/me', {
      headers: {
        Authorization: 'Bearer ' + token,
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    )

      .then((response) => {

        if (response.data.params) {
          sessionStorage.setItem('userdata', JSON.stringify(response.data.params));
          setnom(response.data.params.nom);
          setprenom(response.data.params.prenom);
          setcin(response.data.params.cin);
          setsexe(response.data.params.sexe);
          // setpasswordchange(response.data.params.needChangePassword);
          console.log(token);
        }
       
      })
      .catch((error) => { // Log the error for debugging
        console.error(error);
      });
  };

  let profileImage: string;
  if (sexe === 'm') {
    profileImage = toAbsoluteUrl("/media/avatars/avatar_man.jpg");
  } else if (sexe === 'f') {
    profileImage = toAbsoluteUrl("/media/avatars/avatar_female.jpg");
  } else {
    // If sexe is not "m" or "f", display a default image
    profileImage = toAbsoluteUrl("/media/avatars/blank.png");
  }


  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-300px"
      data-kt-menu="true"
    >
      <div
        className="menu-content bg-info fw-bold d-flex align-items-center bgi-no-repeat bgi-position-y-top rounded-top gradient"

      >
        <div className="symbol symbol-45px mx-5 py-5">
          <div
            className="btn btn-icon btn-sm symbol symbol-40px"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <img src={profileImage} alt="" />
          </div>
        </div>
        <div className="">
          <span className="text-white fw-bolder fs-4">{nom}  {prenom}</span>
          <span className="text-white fw-bold fs-7 d-block">
            {cin}
          </span>
        </div>
      </div>

      {/* begin::Row */}
      <div className="row row-cols-2 g-0">
        <Link
          to="/profile"
          className="border-bottom border-end text-center py-10 btn btn-active-color-primary rounded-0"
          data-kt-menu-dismiss="true"
        >
          <KTSVG
            className="svg-icon-3x me-n1"
            path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
          />
          <span className="  fw-bolder fs-7 d-block pt-3">Mon profil</span>
        </Link>

        {/* <Link
          to="/profile/settings"
          className="col border-bottom text-center py-10 btn btn-active-color-primary rounded-0"
          data-kt-menu-dismiss="true"
        >
          <KTSVG
            className="svg-icon-3x me-n1"
            path="/media/icons/duotone/General/Settings-1.svg"
          />
          <span className="fw-bolder fs-6 d-block pt-3">Settings</span>
        </Link> */}

        {/* <Link
          to="/profile/account"
          className="col text-center border-end py-10 btn btn-active-color-primary rounded-0"
          data-kt-menu-dismiss="true"
        >
          <KTSVG
            className="svg-icon-3x me-n1"
            path="/media/icons/duotone/Shopping/Euro.svg"
          />
          <span className="fw-bolder fs-6 d-block pt-3">Subscriptions</span>
        </Link> */}

        <Link
          to="/logout"
          className="col text-center py-10 btn btn-active-color-primary rounded-0"
          data-kt-menu-dismiss="true"
        >
          <KTSVG
            className="svg-icon-3x me-n1"
            path="/media/icons/duotone/Navigation/Sign-out.svg"
          />

          <span className="fw-bolder fs-7 d-block pt-3" onClick={handlesignout}>Se déconnecter</span>
        </Link>
      </div>
      {/* end::Row */}
    </div>
  );
}

import React from "react";
import { PageTitle } from '../../_start/layout/core';
import { ListsWidget6 } from "../../_start/partials/widgets/lists/ListsWidget6";
import TitlePage from "../modules/fonctions/TitlePage";


export function Sejours() {

    return (
        <>
            <TitlePage title="Mes séjours" />

            <ListsWidget6 className="votre-classe"  />


        </>
    );
}

import React from "react";
import { PageTitle } from '../../_start/layout/core';


export function CommFinnance() {

    return (
        <div>
            <PageTitle>
                Communication Financière
            </PageTitle>

            {/* <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_1"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Communiqués de presse
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_1"
                        className="fs-6 collapse show ps-10"
                        data-bs-parent="#kt_accordion_2"
                    >
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but
                        also the leap into electronic typesetting, remaining
                        essentially unchanged.
                    </div>
                </div>
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_2"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Document assemblée générale
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_2"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                    >
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but
                        also the leap into electronic typesetting, remaining
                        essentially unchanged.
                    </div>
                </div>
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_3"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Rapports financiers
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_3"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                    >
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but
                        also the leap into electronic typesetting, remaining
                        essentially unchanged.
                    </div>
                </div>
            </div> */}
            <iframe
                className=""
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="100%"
                height="500"
                src="https://communicationfinanciere.akdital.ma/#bt_bb_section6537afa6bd988">
            </iframe>
        </div>
    );
}

import React, { useState, useEffect } from "react";
import { PageTitle } from '../../_start/layout/core';
import api from "../api";
import "moment/locale/fr";
import LoaderSpinnerBilan from "../modules/fonctions/LoaderSpinnerBilan";
import Noresults from "../modules/fonctions/Noresults";


interface Labo {
    sejour_id: number;
    num_admission: string;
    prescription_labo_global_id: number;
    prescription_labo_id: number;
    medecin_prescripteur: string;
    laboratoire: string;
    date: string;
    clinique: string;
    details: Array<{
        identifiant_analyse: string;
        libelle: string;
        date: Date
    }>;
}




export function Proche() {

  
    const [datalabo, setdatalabo] = useState<Labo[]>([]);
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        datasejour();
    }, []);



    const datasejour = () => {
        api.get('/patient/bilan-sejours-laboratory')
            .then((response: any) => {
                if (response.data.params) {
                   
                    setdatalabo(response.data.params);

                    setIsLoading(false);
                } else if (response.status === 500) {
                    console.log('An error server.');
                } else {
                    alert('An error occurred.');
                }
            });

    }




    return (
        <div>
            <PageTitle>
                Mes Proches
            </PageTitle>
            {isLoading ? <LoaderSpinnerBilan />
                : (

                    <div className="row g-5">

                        {datalabo && datalabo.length > 0 ? ( datalabo.map((datalabo, index) => (

                            <div key={index} className="col-lg-12">
                                {/* <div className="card card-custom shadow">
                                    <div className="card-header gradient">
                                        <h3 className="card-title text-white">Le {moment(datalabo.date, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</h3>



                                        <div className="card-toolbar">
                                            <a href="#" className="btn btn-white text-primary fs-8">
                                                Télecharger
                                                <i className="fas fa-file-download fs-4 m-2 text-primary"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="card-p">
                                            <span className="text-primary fw-bold fs-5"> N° dossier : {datalabo.num_admission}</span><br />
                                            <span className="text-muted fw-bold fs-7">Clinique : {datalabo.clinique}</span> <br />
                                            <span className="text-muted fw-bold fs-7">Laboratoir  : {datalabo.laboratoire}</span><br />
                                            <span className="text-muted fw-bold fs-7">Médecin  : {datalabo.medecin_prescripteur}</span>

                                         
                                            <div className="separator my-5"></div>
                                            <div className="details">
                                            <span className="text-black fw-bold fs-5">Actes : </span>
                                                {datalabo.details.map((detail, detailIndex) => (
                                                    <React.Fragment key={detailIndex}>
                                                        {detailIndex > 0 ? <span className="text-muted  fw-bold  fs-9"> - </span> : null}
                                                        <span className="text-black fw-bold fs-6">{detail.identifiant_analyse}</span>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                       ))
                       ): (
                        <Noresults />
                       )
                       
                       }

                    </div>
                )}




        </div>
    );
}

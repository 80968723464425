import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import api from "../../../../app/api";
import {
  HeaderUserMenu,
} from "../../../partials";
import { Link } from "react-router-dom";

export function Topbar2() {








  return (
    <>
      {/* begin::Search */}
      {/* <button
        className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
        onClick={() => setShowSearchModal(true)}
      >
        <KTSVG
          path="/media/icons/duotone/General/Search.svg"
          className="svg-icon-1 svg-icon-dark"
        />
      </button> */}
      {/* <SearchModal
        show={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
      /> */}
      {/* end::Search */}


      {/* begin::Message */}
      {/* <button
        className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
        onClick={() => setShowInboxComposeModal(true)}
      >
        <KTSVG
          path="/media/icons/duotone/Communication/Chat6.svg"
          className="svg-icon-1 svg-icon-dark"
        />
      </button>
      <InboxCompose
        show={showInboxComposeModal}
        handleClose={() => setShowInboxComposeModal(false)}
      /> */}
      {/* end::Message */}

      {/* begin::User */}
      <div className="ms-1 ms-lg-6">
        {/* begin::Toggle */}
        
          <Link to='/auth'>
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn gradient fw-bolder fs-8 text-white"

            >
              Espace patient
            </button>
          </Link>
       
        {/* end::Toggle */}
      </div>

      {/* end::User */}

      {/* begin::Notifications */}
      {/* <div className="ms-1 ms-lg-6">
        <button
          className="btn btn-icon btn-sm btn-light-primary fw-bolder pulse pulse-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <span className="position-absolute fs-6">3</span>
          <span className="pulse-ring"></span>
        </button>
        <HeaderNotificationsMenu />
      </div> */}
      {/* end::Notifications */}

      {/* begin::Aside Toggler */}
      {/* {config.aside.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_aside_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )} */}
      {/* end::Aside Toggler */}

      {/* begin::Sidebar Toggler */}
      {/* {config.sidebar.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_sidebar_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )} */}
      {/* end::Sidebar Toggler */}
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { KTSVG } from "../../../helpers";
import { Link } from "react-router-dom";
import api from "../../../../app/api";
import moment from "moment";
import Select from 'react-select';

type Props = {
    className: string;
    innerPadding?: string;
};


const ListsWidget6: React.FC<Props> = (props) => { interface Sejour {
    num_admission: string;
    sejour_id: string;
    entree_reelle: string;
    sortie_reelle: string | null;
    dossier_pere: string | null;
    type_admission: string;
    clinique: string;
    particient_first_name: string;
    particient_last_name: string;
    motif_dmission: string;
    token_consolidation: string;
  }

  const { className = "" } = props;
  const [sejours, setSejours] = useState<Sejour[]>([]);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const uniqueDates = Array.from(
    new Set(sejours?.flatMap((entry) => new Date(entry.entree_reelle).getFullYear()))
  );
  const [currentDate, setCurrentDate] = useState(uniqueDates[currentDateIndex]);
  const [filteredData, setFilteredData] = useState<Sejour[]>([]);

    
    const handleHome = () => {
        api.get('/patient/my-medical-file',)
            .then((response) => {
                if (response.data.params.sejours) {
                    setSejours(response.data.params.sejours.filter((sejour: Sejour) => sejour.dossier_pere === null));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    useEffect(() => {
        handleHome();
      }, []);
    
      useEffect(() => {
        setCurrentDate(uniqueDates[currentDateIndex]);
      }, [currentDateIndex, uniqueDates]);
    
      useEffect(() => {
        if (currentDate) {
          const formattedCurrentYear = currentDate;
          const filteredData = sejours?.filter(
            (item) => {
              const entreeYear = new Date(item.entree_reelle).getFullYear();
              return entreeYear === formattedCurrentYear;
            }
          );
          setFilteredData(filteredData || []);
        }
      }, [currentDate, currentDateIndex, sejours]);
    
      const handlePrevious = () => {
        setCurrentDateIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      };
    
      const handleNext = () => {
        setCurrentDateIndex((prevIndex) => Math.min(prevIndex + 1, uniqueDates.length - 1));
      };
    
      const isAtBeginning = currentDateIndex === 0;
      const isAtEnd = currentDateIndex === uniqueDates.length - 1;
    

    return (
        <>

            <div className="row g-5 mb-10">
                <div className="col-lg-12 ">
                    <div className=" d-flex flex-row justify-content-center align-items-center">
                        <ul className="pagination ">
                            <li
                                className={`page-item next cursor-pointer ${isAtEnd ? "disabled" : ""}`}
                                onClick={handleNext}
                            >
                                <span className="page-link">
                                    <i className="previous"></i>
                                </span>
                            </li>

                            <li className="page-item ">
                                <span className="page-link text-white gradient2">
                                    {moment(currentDate, "YYYY-MM-DD HH:mm:ss").format("YYYY")}
                                </span>
                            </li>
                            <li
                                className={`page-item previous cursor-pointer ${isAtBeginning ? "disabled" : ""}`}
                                onClick={handlePrevious}
                            >
                                <span className="page-link">
                                    <i className="next"></i>
                                </span>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className={`card ${className}`}>

                
                <div className="card-body">
                    {filteredData
                        .map((sejour, index, array) => (
                            <Link to={{ pathname: `/docier-medical/${sejour.token_consolidation}`}}>
                                <div key={index} className={`d-flex ${index === filteredData.length - 1 ? '' : ''}`}  >
                                    <div className="symbol symbol-60px  me-5">
                                        <span className="symbol-label bg-light-success">
                                            <KTSVG
                                                path="/media/icons/duotone/General/Clipboard.svg"
                                                className="svg-icon-1 svg-icon-info"
                                            />
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                            <span className="text-info text-gray-800 fw-bolder  fs-6">{sejour.clinique}</span>
                                            <span className="text-muted  fw-bold  fs-7">{sejour.type_admission} : {sejour.motif_dmission}</span>
                                            <span className="text-muted fw-bold fs-7 ">
                                                Du {moment(sejour.entree_reelle, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")} Au {moment(sejour.sortie_reelle, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}

                                            </span>
                                            <span className="text-muted fw-bold fs-7">
                                                Médecin traitant(e): <span className="text-primary fw-bold">Dr {sejour.particient_first_name} {sejour.particient_last_name}</span>
                                            </span>
                                        </div>
                                        <div className="text-end py-lg-0 ">
                                            <span className="text-gray-800 fw-bold ">{sejour.num_admission}</span>

                                        </div>
                                    </div>

                                </div>
                                {index !== array.length - 1 && ( // Vérifiez si ce n'est pas le dernier élément
                                    <div className="separator  mt-6 mb-6"></div>
                                )}
                            </Link>
                        ))}
                </div>
                {/* end::Body */}
            </div>
        </>
    );
};

export { ListsWidget6 };

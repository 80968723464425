import React, { Suspense, lazy } from "react";
import {Redirect,Route,Switch} from "react-router-dom";

import { FallbackView } from "../../_start/partials";
import { StartDashboardWrapper } from "../pages/dashboards/start-dashboard/StartDashboardWrapper";
import { DocierMedical } from "../pages/DocierMedical";
import {AkditalProche} from "../pages/AkditalProche";
import {CommFinnance} from "../pages/CommFinnance";
import { Reclamation } from "../pages/Reclamation";
import { Sejours } from "../pages/Sejours";
import { Rdv } from "../pages/Rdv";
import { News } from "../pages/News";
import { Bilan } from "../pages/Bilan";
import { Operation } from "../pages/Operation";
import { Ordonnance } from "../pages/Ordonnance";
import { Radio } from "../pages/Radio";
import { Proche } from "../pages/Proche";
import { Assistance } from "../pages/Assistance";
import { AffichePdf } from "../pages/AffichePdf";

export function PrivateRoutes() {
  const ProfilePageWrapper = lazy(
    () => import("../modules/profile/ProfilePageWrapper")
  );
 

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={StartDashboardWrapper} />
        <Route path="/profile" component={ProfilePageWrapper} />
        <Route path="/docier-medical/:token" component={DocierMedical} />
        <Route path="/akdital_proche" component={AkditalProche} />
        <Route path="/commfinnance" component={CommFinnance} />
        <Route path="/Rdv" component={Rdv} />
        <Route path="/Reclamation" component={Reclamation} />
        <Route path="/News" component={News} />
        <Route path="/Bilan" component={Bilan} />
        <Route path="/Operation" component={Operation} />
        <Route path="/Ordonnance" component={Ordonnance} />
        <Route path="/Radio" component={Radio} />
        <Route path="/Proche" component={Proche} />
        <Route path="/assistance" component={Assistance} />
        <Route path="/AffichePdf" component={AffichePdf} />
        <Route path="/sejours" component={Sejours} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}

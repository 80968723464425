import React, { useState, useEffect } from "react";
import { PageTitle } from '../../_start/layout/core';
import { KTSVG } from "../../_start/helpers";
import api from "../api";
import moment from "moment";
import "moment/locale/fr";
import LoaderSpinnerBilan from "../modules/fonctions/LoaderSpinnerBilan";
import Noresults from "../modules/fonctions/Noresults";
import { Link } from "react-router-dom";
import Select from 'react-select';
import CustomIndicator from "../modules/fonctions/CustomIndicator";
import TitlePage from "../modules/fonctions/TitlePage";

interface Radiologie {
    num_admission: string;
    token_consolidation_sejour: string;
    type_examen: string;
    medecin_radiologue: null | string;
    date: string;
    clinique: null | string;
    fileCompteRendu: string
}



export function Radio() {

    const [dataradio, setdataradio] = useState<Radiologie[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentDateIndex, setCurrentDateIndex] = useState(0);
    const uniqueDates = Array.from(
        new Set(dataradio?.flatMap((entry) => new Date(entry.date).getFullYear()))
    );
    const [currentDate, setCurrentDate] = useState(uniqueDates[currentDateIndex]);
    const [filteredData, setFilteredData] = useState<Radiologie[]>([]);








    const datasejour = () => {
        api.get('/patient/bilan-sejours-radiology')
            .then((response: any) => {
                if (response.data.params) {

                    setdataradio(response.data.params);
                    setIsLoading(false);
                } else if (response.status === 500) {
                    // console.log('An error server.');
                } else {
                    alert('An error occurred.');
                }
            });

    }
    useEffect(() => {
        datasejour();
    }, []);

    useEffect(() => {
        setCurrentDate(uniqueDates[currentDateIndex]);
    }, [currentDateIndex, uniqueDates]);

    useEffect(() => {
        if (currentDate) {
            const formattedCurrentYear = currentDate;
            const filteredData = dataradio?.filter(
                (item) => {
                    const entreeYear = new Date(item.date).getFullYear();
                    return entreeYear === formattedCurrentYear;
                }
            );
            setFilteredData(filteredData || []);
        }
    }, [currentDate, currentDateIndex, dataradio]);

    const handlePrevious = () => {
        setCurrentDateIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNext = () => {
        setCurrentDateIndex((prevIndex) => Math.min(prevIndex + 1, uniqueDates.length - 1));
    };

    const isAtBeginning = currentDateIndex === 0;
    const isAtEnd = currentDateIndex === uniqueDates.length - 1;




    return (
        <div>
            <TitlePage title="Mes Examens radio" />
            {isLoading ? <LoaderSpinnerBilan />
                : (

                    <>

                        {filteredData && filteredData.length > 0 && (
                            <div className="row g-5 mb-10">
                                <div className="col-lg-12 ">
                                    <div className=" d-flex flex-row justify-content-center align-items-center">
                                        <ul className="pagination ">
                                            <li
                                                className={`page-item next cursor-pointer ${isAtEnd ? "disabled" : ""}`}
                                                onClick={handleNext}
                                            >
                                                <span className="page-link">
                                                    <i className="previous"></i>
                                                </span>
                                            </li>

                                            <li className="page-item 2">
                                                <span className="page-link text-white gradient">
                                                    {moment(currentDate, "YYYY-MM-DD HH:mm:ss").format("YYYY")}
                                                </span>
                                            </li>
                                            <li
                                                className={`page-item previous cursor-pointer ${isAtBeginning ? "disabled" : ""}`}
                                                onClick={handlePrevious}
                                            >
                                                <span className="page-link">
                                                    <i className="next"></i>
                                                </span>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>

                        )}

                        <div className="row g-5">
                            <div className="accordion " id="kt_accordion_1">
                                {filteredData && filteredData.length > 0 ? (filteredData.map((dataradio, index) => (

                                    <div key={index} className="col-lg-12">
                                        <div className="accordion-item mb-5 " style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                                            <h2 className="accordion-header " id={`kt_accordion_1_header_${index + 1}`}
                                                style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                                            >
                                                <button
                                                    className="accordion-button fs-4  collapsed gradient text-white "
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#kt_accordion_1_body_${index + 1}`}
                                                    aria-expanded="false"
                                                    aria-controls={`kt_accordion_1_body_${index + 1}`}
                                                    style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                                                >
                                                    <h3 className="card-title text-white mt-2">{dataradio.type_examen}</h3>

                                                </button>
                                            </h2>
                                            <div
                                                id={`kt_accordion_1_body_${index + 1}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`kt_accordion_1_header_${index + 1}`}
                                                data-bs-parent="#kt_accordion_1"
                                                style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                                            >
                                                <div className="accordion-body card-body  p-0">
                                                    <div className="row card-p d-flex flex-row justif-content-center align-items-center ">
                                                        <div className="col-lg-8 d-flex flex-column mb-2  ">
                                                            <span className="text-muted fw-bold fs-7">Clinique : {dataradio.clinique}</span>
                                                            <span className=" text-muted fw-bold fs-7">Médecin Radiologue : {dataradio.medecin_radiologue}</span>
                                                            <span className="text-muted fw-bold fs-7">Date : Le {moment(dataradio.date, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</span>
                                                        </div>


                                                        <div className="col-lg-4 ">
                                                            {dataradio.fileCompteRendu ? (

                                                                <Link
                                                                    to={{
                                                                        pathname: `/AffichePdf`,
                                                                        state: { pdfaffiche: `${process.env.REACT_APP_DOMAINNAME}${dataradio.fileCompteRendu}` },
                                                                    }}
                                                                >
                                                                    <CustomIndicator
                                                                        path="/media/icons/duotone/Files/DownloadedFile.svg"
                                                                        label="Voir le document"
                                                                    />

                                                                </Link>

                                                            ) : null}


                                                            <Link to={{ pathname: `/docier-medical/${dataradio.token_consolidation_sejour}` }}>
                                                                <CustomIndicator
                                                                    path="/media/icons/duotone/General/Clipboard.svg"
                                                                    label="Voir le séjour"
                                                                />
                                                            </Link>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))
                                ) : (
                                    <Noresults />
                                )

                                }
                            </div>


                        </div>
                    </>
                )}
        </div>
    );
}
// CustomIndicator.tsx

import React from 'react';
import { KTSVG } from '../../../_start/helpers';

interface CustomIndicatorProps {
  path: string;
  label: string;
}

const CustomIndicator: React.FC<CustomIndicatorProps> = ({ path, label }) => {
  return (
    <span className="indicator-label btn gradient fw-bolder fs-8 text-white me-5 mb-2">
      <KTSVG path={path} className="svg-icon-1 svg-icon-white" />
      {label}
    </span>
  );
};

export default CustomIndicator;

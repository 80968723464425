import React, { useState } from "react";
import { StatsWidget4, StatsWidget7 } from "../../_start/partials/widgets";
import { useIsMobile } from "../../_start/assets/ts/_utils/helpers/dom-helpers/isMobileDevice";
import { ListsWidget2 } from "../../_start/partials/widgets";
import { KTSVG, toAbsoluteUrl } from "../../_start/helpers";
import { PageDataProvider, usePageData, useTheme } from "../../_start/layout/core";
import { AsideDefault } from "../../_start/layout/components/aside/AsideDefault";
import { HeaderWrapper2 } from "../../_start/layout/components/header/HeaderWrapper2";
import { Toolbar } from "../../_start/layout/components/Toolbar";
import { Content } from "../../_start/layout/components/Content";
import { Footer } from "../../_start/layout/components/Footer";
import { ScrollTop } from "../../_start/layout/components/ScrollTop";
import { MasterInit } from "../../_start/layout/MasterInit";
import { Link } from "react-router-dom";
import { Topbar2 } from "../../_start/layout/components/header/Topbar2";
import { MenuModal } from "../../_start/partials";
import { MenuInner2 } from "../../_start/layout/components/header/MenuInner2";

export function Home() {
    const isMobile = useIsMobile();

    const [show, setShow] = useState(false);
    const [showMegaMenuModal, setShowMegaMenuModal] = useState(false);
    const { config, classes, attributes } = useTheme();
    const { pageTitle, moduleName } = usePageData();
    return (


        <>
            {isMobile ? (
                <PageDataProvider>


                    <div
                        className="carousel-content-wrapper d-flex align-items-center justify-content-center"
                        style={{
                            background: `linear-gradient(to bottom, #FFFFFF, transparent 30%, #0376b9), url('${toAbsoluteUrl("/media/imagescliniques/patient2.jpg")}')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100vh',
                        }}
                    >
                        <div className="content-wrapper text-center">
                            <div className="d-flex flex-column flex-root">
                                <div className="page d-flex flex-row flex-column-fluid">
                                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                        <div
                                            className={`${classes.headerContainer.join(" ")} d-flex align-items-stretch justify-content-between   mt-5 `}{...Object.fromEntries(Array.from(attributes.headerContainer))}
                                            style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000, }}>

                                            <div className="d-flex align-items-center">
                                                <Link to="/home">
                                                    <img
                                                        alt="Logo"
                                                        src={toAbsoluteUrl("/media/logos/logo_akdital2.svg")}
                                                        className="h-40px"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                {config.aside.content && config.aside.content === "menu" && (
                                                    <h3 className="text-dark fw-bolder my-1 fs-2">{pageTitle}</h3>
                                                )}
                                                {config.aside.content && config.aside.content === "docs" && (
                                                    <>
                                                        <button
                                                            className="btn btn-icon btn-accent me-3 me-lg-6"
                                                            id="kt_mega_menu_toggle"
                                                            onClick={() => setShowMegaMenuModal(true)}
                                                        >

                                                        </button>
                                                        <h3 className="d-flex align-items-center text-dark fw-bolder my01 fs-5 fs-lg-2">
                                                            <span className="d-none d-lg-inline">{moduleName}</span>&nbsp;
                                                            {pageTitle && (
                                                                <span
                                                                    className="badge badge-light-danger fw-bold fs-8 shadow-sm ms-2">
                                                                    {process.env.REACT_APP_VERSION}
                                                                </span>
                                                            )}
                                                        </h3>
                                                    </>
                                                )}
                                                {!config.aside.content && (
                                                    <>
                                                        <button
                                                            className="btn btn-icon bg-lighten me-2 me-lg-6"
                                                            id="kt_mega_menu_toggle"
                                                            onClick={() => setShowMegaMenuModal(true)}
                                                        >
                                                            <KTSVG
                                                                path="/media/icons/duotone/Text/Article.svg"
                                                                className="svg-icon-1 svg-icon-white"
                                                            />
                                                        </button>


                                                    </>
                                                )}
                                            </div>



                                        </div>
                                        <MenuModal
                                            show={showMegaMenuModal}
                                            handleClose={() => setShowMegaMenuModal(false)}
                                        >
                                            <MenuInner2 />
                                        </MenuModal>



                                    </div>
                                </div>
                            </div>

                            <p className="title">Connectez-vous pour consulter vos données médicales en toute sécurité.</p>
                            <Link to='/auth'>
                                <button className="btn btn-primary">Espace patient</button>
                            </Link>
                        </div>
                    </div>


                    <ScrollTop />
                    <MasterInit />
                </PageDataProvider >
            ) : (
                <PageDataProvider>
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <AsideDefault />
                            <div
                                className="wrapper d-flex flex-column flex-row-fluid"
                                id="kt_wrapper"
                            >
                                <HeaderWrapper2 />
                                <div className="d-flex flex-column flex-column-fluid">
                                    <Toolbar />
                                    <div
                                        className="content fs-6 d-flex flex-column-fluid"
                                        id="kt_content"
                                    >
                                        <Content> <StatsWidget4 className="card-stretch  mb-xxl-8 " /></Content>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                            {/* <Sidebar /> */}
                        </div>
                    </div>
                    <ScrollTop />
                    <MasterInit />
                </PageDataProvider>
            )
            }

        </>

    )
        ;
}
;

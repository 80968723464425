import React from "react"
import ContentLoader from "react-content-loader"

interface LoaderSpinnerProps {
  // Define the specific props and their types
  // For example, if you have any additional props
  // specific to LoaderSpinner, define them here
}



const LoaderSpinnerBilan: React.FC<LoaderSpinnerProps> = (props) => (
  <div className="row g-5">
    <div className="col-lg-12 ">
      <div className="card card-custom card-flush">
        <div className="card-body">
          <ContentLoader
            speed={2}
            height={150}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
            <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
            <rect x="0" y="56" rx="3" ry="3" width="210" height="6" />
            <rect x="0" y="72" rx="3" ry="3" width="170" height="6" />
            <rect x="0" y="88" rx="3" ry="3" width="120" height="6" />
            <circle cx="20" cy="20" r="20" />
          </ContentLoader>
        </div>
      </div>

    </div>
    <div className="col-lg-12 ">
      <div className="card card-custom card-flush">
        <div className="card-body">
          <ContentLoader
            speed={2}
            height={150}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
            <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
            <rect x="0" y="56" rx="3" ry="3" width="210" height="6" />
            <rect x="0" y="72" rx="3" ry="3" width="170" height="6" />
            <rect x="0" y="88" rx="3" ry="3" width="120" height="6" />
            <circle cx="20" cy="20" r="20" />
          </ContentLoader>
        </div>
      </div>

    </div>
    <div className="col-lg-12 ">
      <div className="card card-custom card-flush">
        <div className="card-body">
          <ContentLoader
            speed={2}
            height={150}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
            <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
            <rect x="0" y="56" rx="3" ry="3" width="210" height="6" />
            <rect x="0" y="72" rx="3" ry="3" width="170" height="6" />
            <rect x="0" y="88" rx="3" ry="3" width="120" height="6" />
            <circle cx="20" cy="20" r="20" />
          </ContentLoader>
        </div>
      </div>

    </div>
  </div>
)

export default LoaderSpinnerBilan
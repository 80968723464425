/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import api from "../../../../app/api";
import { Redirect } from "react-router-dom";
// import { KTSVG } from "../../../helpers";

type Props = {
  className: string;
};

const TablesWidget3: React.FC<Props> = ({ className }) => {
  const [data] = useState(sessionStorage.getItem('userdata'));
  const [loading, setLoading] = useState(true);
  const [irrors, seterror] = useState("");
  const [redirectToparofil, setredirectToparofil] = useState<boolean>();
  const [currentpassword, setcurrentpassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");

  const validatePassword = () => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  
    if (password !== confirmpassword) {
      seterror("Le nouveau mot de passe ne correspond pas. Retapez le nouveau mot de passe.");
      setLoading(true);
      return false;
    }
  
    if (!passwordPattern.test(password)) {
      seterror("Le mot de passe ne respecte pas le modèle requis.");
      setLoading(true);
      return false;
    }
  
    return true;
  };
 

  const editpass = () => {
    const token = sessionStorage.getItem('token');
  
    // Vérifier si le nouveau mot de passe respecte les critères
    if (validatePassword()) {
      const formData = new URLSearchParams();
      formData.append('current_password', currentpassword);
      formData.append('new_password', password);
  
      // Envoyer la requête de modification du mot de passe
      api.put('/auth_mobile/password', formData, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response: any) => {
        if (response.status === 202) {
          console.log("done");
          seterror('Modification du mot de passe réussie. Veuillez utiliser le nouveau mot de passe pour vous connecter.');
          setLoading(true);
          setTimeout(() => {
            setredirectToparofil(true);
          }, 2000);
        }
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 406) {
          seterror('Le mot de passe entré est incorrect. Veuillez réessayer.');
        } else {
          seterror('Une erreur est survenue lors de la modification du mot de passe.');
        }
        setLoading(true);
      });
    }
  };

  return (
    <>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade active show"
          id="kt_tab_pane_1"
          role="tabpanel"
        >
          <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header align-items-center  mb-5 ">
              <h3 className="card-title  text-black fs-4">
                Informations personnelles
              </h3>
              {/* <div className="card-toolbar">
          <button type="button" className="btn btn-sm btn-light">

            Modifier
          </button>
        </div> */}
            </div>

            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body">
              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">Nom</label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">{data ? JSON.parse(data).nom : "undefdddined"}</span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">Prénom</label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">{data ? JSON.parse(data).prenom : "undefdddined"}</span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">Cin</label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">{data ? JSON.parse(data).cin : "undefdddined"}</span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">Téléphone</label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">{data ? JSON.parse(data).tel : "undefdddined"}</span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">Date de naissance</label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">{data ? JSON.parse(data).naissance : "undefdddined"}</span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">Ville</label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">{data ? JSON.parse(data).ville : "undefdddined"}</span>
                </div>
              </div>
            </div>
            {/* end::Body */}
          </div>
        </div>
        <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
          <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header align-items-center  mb-5 ">
              <h3 className="card-title  text-black fs-4">
                Connexion et sécurité
              </h3>
            </div>

            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body">
              <form
                className="form w-100"
                onSubmit={(e) => {
                  e.preventDefault();
                  setLoading(false);
                  editpass();
                }}

                id="kt_login_signin_form"
              >

                {irrors === "Modification du mot de passe réussie. Veuillez utiliser le nouveau mot de passe pour vous connecter." ? (
                  <div className="mb-lg-15 alert alert-success">
                    <div className="alert-text font-weight-bold">{irrors}</div>
                  </div>
                ) : (irrors === "Le mot de passe entré est incorrect. Veuillez réessayer."|| irrors==="Une erreur est survenue lors de la modification du mot de passe." || irrors === "Le nouveau mot de passe ne correspond pas. Retapez le nouveau mot de passe." || irrors === "Le mot de passe ne respecte pas le modèle requis."  ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{irrors}</div>
                  </div>
                ) : (
                  <></>
                ))}

                <div className="row mb-1">
                  <div className="col-lg-4">
                    <div className="fv-row mb-4 fv-plugins-icon-container">
                      <label className="form-label fs-6 fw-bold text-dark mb-2">Mot de passe actuel</label>
                      <input className="form-control form-control-lg form-control-solid" type="password" name="currentpassword" required   autoComplete="off" onChange={(e) => { setcurrentpassword(e.target.value) }} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fv-row mb-4 fv-plugins-icon-container">
                      <label className="form-label fs-6 fw-bold text-dark mb-2">Nouveau mot de passe</label>
                      <input className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" required   onChange={(e) => { setpassword(e.target.value) }}/>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5">
                    <div className="fv-row mb-4 fv-plugins-icon-container">
                      <label className="form-label fs-6 fw-bold text-dark mb-2">Confirmer le nouveau mot de passe</label>
                      <input className="form-control form-control-lg form-control-solid" type="password" name="confirmpassword" autoComplete="off" required   onChange={(e) => { setconfirmpassword(e.target.value) }}/>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3 ">
                      {loading ? <span className="indicator-label">Enregistrer</span> : <span className="indicator-progress" style={{ display: "block" }}>
                        {i18next.t('wait')}{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>}
                    </button>
                    {/* Redirection après un délai */}
                    {redirectToparofil  && <Redirect to="/profile" />}
                    {/* ... */}

                    <Link to="/profile">
                      <button
                        type="button"
                        id="kt_login_password_reset_form_cancel_button"
                        className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3 "

                      >
                        {i18next.t('cancel')}
                      </button>
                    </Link>{" "}
                  </div>
                </div>
              </form>

            </div>
            {/* end::Body */}
          </div>
        </div>

      </div>



    </>
  );
};

export { TablesWidget3 };

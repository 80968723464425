import React, { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { toAbsoluteUrl } from "../../_start/helpers";
import { datacliniques } from "./DataClinique";
import moment from "moment/moment";
interface Clinique {
  clinic_name: string;
  sous_name: string | null;
  adresse: string;
  cp: null | string;
  ville: string;
  tel: string;
  web: null |string;
  gps_degres_decimaux: string;
  code_etablissement:string;
  img:string;
  img2: string
}


interface CliniqueAvecDistance extends Clinique {
  distance: number;
}

const Map: React.FC = () => {
  let map: google.maps.Map;
  const [cliniquesTriees, setCliniquesTriees] = useState<CliniqueAvecDistance[]>([]);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);


  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyDKYhhYxzUeMl99isDiYs8HTSijgOOJTS8",
      version: "weekly",
    });

    loader.load().then(async () => {
        const { Map } = google.maps;


        if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
               lat: 33.54151132016799,
               lng: -7.655139375067126,

            };


            map = new Map(document.getElementById("map") as HTMLElement, {
              center: userLocation,
              zoom: 10.5,
              mapId: "3023c03233a0a824",
            });




            const cliniquesTriees = datacliniques.map((clinique) => {
              const coords = clinique.gps_degres_decimaux.split(', ');
              const lat = parseFloat(coords[0]);
              const lng = parseFloat(coords[1]);
              const distanceInKm = getDistanceInKm(userLocation.lat, userLocation.lng, lat, lng);

              return {
                ...clinique,
                distance: distanceInKm,
              };
            });

            cliniquesTriees.sort((a, b) => a.distance - b.distance);

            setCliniquesTriees(cliniquesTriees);

            const addMarkers = (cliniques: CliniqueAvecDistance[]) => {
              const newMarkers: google.maps.Marker[] = [];


              cliniques.forEach((clinique) => {
                const coords = clinique.gps_degres_decimaux.split(', ');
                const lat = parseFloat(coords[0]);
                const lng = parseFloat(coords[1]);

                  const userIconUrl = toAbsoluteUrl("/media/illustrations/patient2.svg");
                  const userIconUrlclinique = toAbsoluteUrl("/media/illustrations/icon_map_akdital.png");
                  const smallIconSize = new google.maps.Size(85, 85);
                  const smallIconSizeclinique = new google.maps.Size(45, 45);
                const marker = new google.maps.Marker({
                  position: { lat, lng },
                  map,
                    icon: {
                        url: userIconUrlclinique,
                        scaledSize: smallIconSizeclinique,
                    },

                });


                const userMarker = new google.maps.Marker({
                  position: userLocation,
                  map,
                  title: "Votre position actuelle",
                  icon: {
                    url: userIconUrl,
                    scaledSize: smallIconSize,
                  },
                });
                const infoWindow = new google.maps.InfoWindow({
                  content: `
              <div class="custom-info-window">
                <h3>${clinique.clinic_name}</h3>
                <p>Adresse: ${clinique.adresse}</p>
                <p>Téléphone: ${clinique.tel}</p>
              </div>
            `,
                });

                marker.addListener("click", () => {
                  infoWindow.open(map, marker);
                });
                newMarkers.push(marker);
              });

              setMarkers(newMarkers);
            };

            addMarkers(cliniquesTriees);
          },
          (error) => {
            // Gérer les erreurs
            if (error.code === error.PERMISSION_DENIED) {
              alert("Vous avez refusé la géolocalisation. Veuillez autoriser la géolocalisation pour utiliser cette fonctionnalité.");
            } else if (error.code === error.POSITION_UNAVAILABLE) {
              alert("La position n'a pas pu être déterminée.");
            } else if (error.code === error.TIMEOUT) {
              alert("La demande de géolocalisation a expiré.");
            } else {
              alert("Une erreur inconnue s'est produite.");
            }

            // Le code de géolocalisation peut également continuer ici en utilisant une valeur par défaut ou en demandant une adresse.
          },
          { timeout: 10000 }
        );
      }
      else {
        alert("La géolocalisation n'est pas prise en charge par votre navigateur.");
        // Le code de géolocalisation peut également continuer ici en utilisant une valeur par défaut ou en demandant une adresse.
      }
    });
  }, []);

  // Fonction pour calculer la distance en kilomètres
  const getDistanceInKm = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const rad = (x: number) => (x * Math.PI) / 180;
    const R = 6371; // Rayon de la Terre en kilomètres

    const dLat = rad(lat2 - lat1);
    const dLon = rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };


  return (
    <>
      <div id="map" className="mb-5" style={{ height: '400px', width: '100%' }}></div>
      <div className="row g-5">
        {cliniquesTriees.map((clinique, index) => (
          <div key={index} className="col-lg-4">
            <div className="card card-custom shadow">

                <div className="card-header"
                     style={{height:"150px" ,
                         backgroundImage: `url('${clinique.img2}')`,
                         backgroundSize:'cover'
                     }}
                >

                </div>

                <div className="card-body d-flex flex-column justify-content-center " style={{ height: '80px' }} >
                    <a href="#" className=" fw-bolder text-hover-primary fs-6"> {clinique.clinic_name}</a>
                    <a href="#" className=" fw-bolder text-muted fs-7"> {clinique.sous_name}</a>

                </div>



              <div className="card-footer">
              <div className="d-flex flex-row justify-content-start ">
                <button
                  type="button"
                  className="btn gradient fw-bolder  text-white fs-7 py-1 px-3 me-3 me-sm-4 "
                >
                  à {clinique.distance.toFixed(2)} km
                </button>
                <button
                  type="button"
                  className="btn btn-success fw-bolder fs-6 py-1 px-3 me-3 me-sm-4 bg-succes"
                // style={{
                //   background: 'linear-gradient(-120deg, #4285F4, #34A853, #FBBC05, #EA4335)',
                // }}
                >
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${clinique.clinic_name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-directions fs-1x text-white" style={{ fontSize: '16px' }}></i>{' '}
                    <span className="fs-7 text-white">Maps</span>
                  </a>
                </button>
              </div>
            </div>

            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Map;







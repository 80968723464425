/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { KTSVG } from "../../../helpers";
import { Link } from "react-router-dom";
import api from "../../../../app/api";
import moment from "moment";

type Props = {
  className: string;
  innerPadding?: string;
  showHeader: boolean;
  showRowCount: number;
};

const ListsWidget2: React.FC<Props> = (props) => {
  const { className = "", showHeader, showRowCount } = props;
  const [sejours, setSejours] = useState<Sejour[]>([]);
  // const [sejourCount, setSejourCount] = useState<number>(0);
  // const [showAllsejour, setshowAllsejour] = useState(false);
  const handleHome = useCallback(() => {
    api.get('/patient/my-medical-file',)
      .then((response) => {
        if (response.data.params.sejours) {
          setSejours(response.data.params.sejours.filter((sejour: Sejour) => sejour.dossier_pere === null));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    handleHome();
  }, [handleHome]);



  interface Sejour {
    num_admission: string;
    sejour_id: string;
    entree_reelle: string;
    sortie_reelle: string | null;
    dossier_pere: string | null;
    type_admission: string;
    clinique: string;
    particient_first_name: string;
    particient_last_name: string;
    motif_dmission: string;
    token_consolidation: string;
  }



  return (
    <div className={`card ${className}`}>

      {/* begin::Header */}
      {showHeader && (

        <div className="card-header align-items-center border-0 mb-5 gradient "
        >
          <h3 className="card-title align-items-start flex-column">
            

            <span className="text-white fs-3  fw-bolder" >Mes séjours  <span className="fs-6 text-white  fw-bold">({sejours.length})</span></span>


          </h3>
          <div className="card-toolbar ">

            <Link
              to="/sejours"
              className=" "
              data-kt-menu-dismiss="true"
            >
              <span className="text-white  fw-bolder  fs-6 ">Voir tout</span>
            </Link>
          </div>
        </div>
      )}
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {sejours
          .slice(0, showRowCount)
          .map((sejour, index) => (
            <Link to={{ pathname: `/docier-medical/${sejour.token_consolidation}`}}>
              <div key={index} className={`d-flex ${index === sejours.length - 1 ? '' : 'mb-7'}`}  >
                <div className="symbol symbol-60px  me-5">
                  <span className="symbol-label bg-light-success">
                    <KTSVG
                      path="/media/icons/duotone/General/Clipboard.svg"
                      className="svg-icon-1 svg-icon-primary"
                    />
                  </span>
                </div>
                <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                  <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                    <span className="text-info text-gray-800 fw-bolder  fs-6">{sejour.clinique}</span>
                    <span className="text-muted  fw-bold  fs-7">{sejour.type_admission} : {sejour.motif_dmission}</span>
                    <span className="text-muted fw-bold fs-7 ">
                      Du {moment(sejour.entree_reelle, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")} Au {moment(sejour.sortie_reelle, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}

                    </span>
                    <span className="text-muted fw-bold fs-7">
                      Médecin traitant(e): <span className="text-primary fw-bold">Dr {sejour.particient_first_name} {sejour.particient_last_name}</span>
                    </span>
                  </div>
                  <div className="text-end py-lg-0 py-2">
                    <span className="text-gray-800 fw-bold ">{sejour.num_admission}</span>

                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListsWidget2 };

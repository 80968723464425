/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { toAbsoluteUrl } from "../../../helpers";
// import { KTSVG } from "../../../helpers";
import api from "../../../../app/api";

type Props = {
  className: string;
  innerPadding?: string;
};

const EngageWidget5: React.FC<Props> = ({
  className,
  innerPadding = "",
  children,
}) => {
  
  useEffect(()=>{
    handleHome();
  },[]);
  const [nom, setnom] = useState('');
  const [prenom, setprenom] = useState('');

  const handleHome =  () => { 
  const token =  sessionStorage.getItem('token');
  if(token){
    console.log(token);
 }
 else{
   console.log("nnnn");
 }
    api.get('/auth_mobile/me',{
      headers: {
        Authorization: 'Bearer ' + token,
        'content-type':'application/x-www-form-urlencoded'
      }
    }
    )

      .then((response) => {
        
        if (response.data.params) {
          sessionStorage.setItem('userdata',JSON.stringify(response.data.params));
          setnom(response.data.params.nom);
          setprenom(response.data.params.prenom);
          console.log(token);
        } 
        
      })
      .catch((error) => { // Log the error for debugging
        console.error(error);
      });
  };

  return (
    <div className={`card gradient ${className}`}>
      {/* begin::Body */}
      <div className={`card-body pb-0 ${innerPadding} `}>
        {/* begin::Wrapper */}
        <div className="d-flex flex-column h-80">
          
          {/* begin::Text */}
          <h3 className="text-white text-center fs-1 fw-bolder  lh-lg">
            Bonjour 
            <br />
           {nom}  {prenom}
           <br />
           {/* {passwordchange  && passwordchange? (
           <h3 className="text-white text-center fs-1 fw-bolder  lh-lg">Tu dois changer le mot de passe </h3>
           ) : (
            <h3 className="text-white text-center fs-1 fw-bolder  lh-lg">Tu ne dois pas changer le mot de passe</h3>
            )
          
          } */}
           {/* {userdata ? JSON.parse(userdata).firstName : "undefdined"} */}
          </h3>
          {/* end::Text */}
          
          {/* begin::Image */}
          <div
            className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom h-200px"
            style={{
              backgroundImage: `url('${toAbsoluteUrl(
                "/media/illustrations/HOME.svg"
              )}')`,
            }}
          ></div>

          {/* end::Image */}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { EngageWidget5 };

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import i18next from "../../../i18n";
import api from "../../../api";

export function Authcode() {
  const [loading, setLoading] = useState(true);
  const [codeauth, setauthcode] = useState("");
  const [irrors, seterror] = useState("");
  const [redirectToEditpass, setRedirectToEditpass] = useState<boolean>();
  const [cin, setcin] = useState<string | null>(null);
  const [date_naissance, setdate_naissance] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState("");


  useEffect(() => {
const date_naiss = sessionStorage.getItem('date_naissance') ;
const cin_patient = sessionStorage.getItem('cin') ;
setcin(cin_patient)
setdate_naissance(date_naiss)
  }, []);

  const handleReceivepass = () => {
    setLoading(false);
    const formData = new FormData();
    if (cin !== null) {
      formData.append('cin', cin);
    }
    if (date_naissance !== null) {
      formData.append('date_naissance', date_naissance);
    }


    api.post('/auth_mobile/request_password', formData
    )
      .then((response) => {
        if (response.data.debug) {
          console.log(response.data.debug);
          const newPhoneNumber = response.data.params.telephone;
          setPhoneNumber(newPhoneNumber);
          seterror(i18next.t('done') + newPhoneNumber + i18next.t('done2'));
          setLoading(true);

        } else if (!response.data.debug) {
          seterror(i18next.t('error1_login'));
          setLoading(true);

        }
        else {
          alert('An error occurred.');
        }

      })
      .catch((error) => {
        seterror(i18next.t('error1_login'));
        setLoading(true);
      });
  };

  const handleauthcode = () => {
    setLoading(false);
    const formData = new FormData();
    if (cin !== null) {
      formData.append('cin', cin);
    }
    if (date_naissance !== null) {
      formData.append('date_naissance', date_naissance);
    }

    formData.append('auth_code', codeauth);

     console.log(formData)
    api.post('/auth_mobile/auth-code', formData
    )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          console.log("done");
          sessionStorage.setItem('codeauth', codeauth);
          setTimeout(() => {
            setRedirectToEditpass(true);
          }, 5000);

        }
        

      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          seterror('INCORRECT_AUTH_CODE');
        }
        else if(error.response && error.response.status === 400) {
          seterror('PATIENT NOT FOUND');
        }
        setLoading(true);
      });
  };

  return (
    <div className="d-flex flex-column flex-lg-row-fluid">
      {/* Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid">
        {/* Wrapper */}
        <div className="w-lg-500px p-10 p-lg-15 mx-auto">
          {/* Form */}
          <form
            className="form w-100"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(false);
              handleauthcode();
            }}

            id="kt_login_signin_form"
          >

            {/* Heading */}
            <div className="mb-10">
              <h1 className="text-dark mb-3 display-6">
                Vérification
              </h1>
              {/* Link */}
              <div className="text-gray-400 fw-semibold fs-4">
                Entrez le code de vérification <br />
                {/* <Link
                to="/auth/registration"
                className="text-primary fw-bold"
                id="kt_login_signin_form_singup_button"
              >
                si vous ne recevez pas de code, renvoyez
              </Link> */}

              </div>
            </div>
            {/* Heading */}
            {/* Heading */}
            {/* Input group */}
            {/* {irrors ? (
              <div className="mb-lg-15 alert alert-danger ">
                <div className="alert-text fs-7 font-weight-bold  ">{irrors}</div>
              </div>
            ) : ""} */}

            {irrors === i18next.t('done') + phoneNumber + i18next.t('done2') ? (
              <div className="mb-lg-15 alert alert-success">
                <div className="alert-text font-weight-bold">{irrors}</div>
              </div>
            ) : (irrors === "INCORRECT_AUTH_CODE" || irrors ==="PATIENT NOT FOUND" ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">{irrors}</div>
              </div>
            ) : (
              <></>
            ))}

            <div className="fv-row mb-10">
              {/* Input */}
              <input className="form-control form-control-lg form-control-solid" type="text" name="cin" autoComplete="off" placeholder="code" onChange={(e) => { setauthcode(e.target.value) }} />
              <div className="d-flex flex-row justify-content-start  form-label fs-7  fw-bold mt-4">

                {/* Link */}
                si vous ne recevez pas de code ,
                {/* <Link
                  to="/auth/forgot-password"
                  // className="text-primary fs-6 fw-bolder  "
                
                  id="kt_login_signin_form_password_reset_button"
                >
                  
                </Link> */}

                <a className="form-label fs-7  fw-bold text-primary text-hover-primary" onClick={handleReceivepass}> renvoyez le code</a>



              </div>

            </div>
            {/* Actions */}
            <div className="">
              {/* Submit button */}
              <button type="submit" id="kt_sign_in_submit" className="btn gradient fw-bolder fs-6 px-8 py-4 my-3 me-3">
                {loading ? <span className="indicator-label text-white">Envoyer</span> : <span className="indicator-progress text-white" style={{ display: "block" }}>
                  {i18next.t('wait')}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>}
              </button>
              {/* Redirection après un délai */}
              {redirectToEditpass && <Redirect to="/auth/new-pass" />}
              {/* ... */}
            </div>
            {/* Actions */}
          </form>
          {/* Form */}
        </div>
        {/* Wrapper */}
      </div>
      {/* Content */}

    </div>
  );
}

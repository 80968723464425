import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthPage } from "../modules/auth";
import { AkditalProche } from "../pages/AkditalProche";
import { Home } from "../pages/Home";
import { Assistance } from "../pages/Assistance";
import { MasterLayout2 } from "../../_start/layout/MasterLayout2";

type ComponentType = React.ComponentType<any>;
const withMasterLayout = (Component: ComponentType) => (props: any) => (
  <MasterLayout2>
    <Component {...props} />
  </MasterLayout2>
);

export function PublicRoutes() {
  return (
    <Switch>
      <Route path="/auth" component={AuthPage} />
      
      <Route path="/akdital_proche" component={withMasterLayout(AkditalProche)} />
      <Route path="/assistance" component={withMasterLayout(Assistance)} />
      <Route path="/home" component={Home} />
      
      <Redirect to="/home" />
    </Switch>
  );
}

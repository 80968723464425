// GoBack.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import { KTSVG } from '../../../_start/helpers';

interface GoBackProps {
  title: string;
}

const TitlePage: React.FC<GoBackProps> = ({ title }) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="d-flex flex-row align-items-center" style={{ marginTop: '-2rem', marginBottom: '0.25rem' }}>
      <h3 className="card-title symbol symbol-40px cursor-pointer" onClick={handleGoBack}>
        <span className="symbol-label bg-light-success">
          <KTSVG
            className="svg-icon-1 svg-icon-primary"
            path="/media/icons/duotone/Navigation/Arrow-left.svg"
          />
        </span>
      </h3>
      <h3 className="">{title}</h3>
    </div>
  );
};

export default TitlePage;

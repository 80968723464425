import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import i18next from "../../../i18n";
import api from "../../../api";


export function Login() {

  const [loading, setLoading] = useState(true);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [irrors, seterror] = useState("");

  console.log(i18next.language)


  useEffect(() => {
    // handleLogin()
  }, []);


  const handleLogin = () => {
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    api.post('/auth_mobile/login', formData)
      .then((response) => {
        if (response.data.params.token) {
          sessionStorage.setItem('token', response.data.params.token);
          console.log(response.data.params.token);
          setLoading(false);
          window.location.reload();
        } else if (response.data.params === "INCORRECT_PASSWORD") {
          seterror(i18next.t('error1_login'));
        }
        else {
          alert('An error occurred.');
        }

      })
      .catch((error) => { // Log the error for debugging
        if (error.response && error.response.status === 400) {
          seterror(i18next.t('error1_login'));
        }
        else {
          seterror('An error occurred while making the request.');
        }
        setLoading(true);
      });
  };
  return (
    <div className="d-flex flex-column flex-lg-row-fluid">
      {/* Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid">
        {/* Wrapper */}
        <div className="w-lg-500px p-10 p-lg-15 mx-auto">
          {/* Form */}
          <form
            className="form w-100"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(false);
              handleLogin();
            }}

            id="kt_login_signin_form"
          >

            {/* Heading */}
            <div className="mb-10">
              <h1 className="text-dark mb-3 display-6">
                {i18next.t('title')}
              </h1>
              {/* Link */}
              <div className="text-gray-400 fw-semibold fs-4">
                {i18next.t('new')}
                <Link
                  to="/auth/registration"
                  className="text-primary fw-bold"
                  id="kt_login_signin_form_singup_button"
                >
                  {i18next.t('receivepass')}
                </Link>
              </div>
            </div>
            {/* Heading */}
            {/* Input group */}
            {irrors ? (
              <div className="mb-lg-15 alert alert-danger ">
                <div className="alert-text fs-7 font-weight-bold  ">{i18next.t('error1_login')}</div>
              </div>
            ) : ""}
            <div className="fv-row mb-10">
              {/* Label */}
              <label className="form-label fs-6 fw-bold text-dark">{i18next.t('cin')}</label>
              {/* Input */}
              <input className="form-control form-control-lg form-control-solid" type="text" name="cin" autoComplete="off" onChange={(e) => { setusername(e.target.value) }} />
            </div>
            {/* Input group */}
            {/* Input group */}
            <div className="fv-row mb-10">
              {/* Wrapper */}
              <div className="d-flex flex-stack mb-2">
                {/* Label */}
                <label className="form-label fw-bold text-dark fs-6">{i18next.t('pass')}</label>
                {/* Link */}
                <Link
                  to="/auth/forgot-password"
                  // className="text-primary fs-6 fw-bolder  "
                  className="form-label fs-7  fw-bold text-primary text-hover-primary"
                  id="kt_login_signin_form_password_reset_button"
                >
                  {i18next.t('forgoutpass')}
                </Link>


              </div>
              {/* Input */}
              <input className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" onChange={(e) => { setpassword(e.target.value) }} />
            </div>

            {/* Input group */}
            {/* Actions */}
            <div className="">
              {/* Submit button */}
              <button type="submit" id="kt_sign_in_submit" className="btn gradient fw-bolder fs-6 px-8 py-4 my-3 me-3">
                {loading ? <span className="indicator-label text-white">  {i18next.t('login')}</span> : <span className="indicator-progress text-white" style={{ display: "block" }}>
                  {i18next.t('wait')}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>}
              </button>
            </div>
            {/* Actions */}
          </form>
          {/* Form */}
        </div>
        {/* Wrapper */}
      </div>
      {/* Content */}

    </div >
  );
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "cancel":"Cancel",
      "send":"Submit",
      "title": "Log in",
      "new": " New here? ",
      "receivepass": "Receive password",
      "cin": "Cin",
      "pass": "Password",
      "forgoutpass": "Forgout password ?",
      "textpassword":"Enter your cin and your date of birth to reset your password",
      "datenaiss":"Date of Birth",
      "login": "Login",
      "slogan":"Your health, our priority",
      "error1_login":"Unauthorized access. Please check your credentials.",
      "done":"We have sent your password to this number ",
      "done2":"**** please check your messages.",
      "wait":" Please wait..."
    }
  },
  fr: {
    translation: {
      "cancel":"Annuler",
      "send":"Envoyer",
      "title": "Connectez-vous",
      "new": " Nouveau ici? ",
      "receivepass": "Recevoir le mot de passe ",
      "cin": "Cin",
      "pass": "Mot de passe",
      "forgoutpass": "Mot de passe oublié ?",
      "textpassword":"Entrez votre votre Cin et votre date de naissance pour réinitialiser votre mot de passe",
      "datenaiss":"Date de naissance",
      "login": "Se connecter",
      "slogan":"Votre santé, notre priorité",
      "error1_login":"Accès non autorisé. Veuillez vérifier vos informations d'identification",
      "done":"Nous vous avons envoyé votre mot de passe sur ce numéro ",
      "done2":", veuillez consulter vos messages.",
      "wait":" Veuillez patienter..."
    }
  },
  ar: {
    translation: {
      "cancel":"إلغاء",
      "send":"أرسل",
      "title": "قم بتسجيل الدخول",
      "new": " جديد هنا؟",
      "receivepass": "استقبل كلمة المرور",
      "cin": "رقم البطاقة الوطنية",
      "pass": " كلمة المرور",
      "forgoutpass": "هل نسيت كلمة المرور؟",
      "textpassword":"أدخل رقم بطاقتك الوطنية وتاريخ ميلادك لإعادة تعيين كلمة مرورك",
      "datenaiss":"تاريخ الميلاد",
      "login": " تسجيل الدخول",
      "slogan":"صحتكم، أولويتنا",
      "error1_login":" الوصول غير مسموح به. يرجى التحقق من بيانات الاعتماد الخاصة بك ",
      "done":"لقد أرسلنا كلمة المرور الخاصة بك على هذا الرقم ***",
      "done2":"    يرجى مراجعة رسائلك",
      "wait":" ...الرجاء الانتظار"
     }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
import React from "react";
import TitlePage from "../modules/fonctions/TitlePage";
import Map from "./map";

export function AkditalProche() {
  return (
    <div>
      <TitlePage title="Akdital la plus proche" />

      <Map />


    </div>

  );


}

import React from "react";
import { PageTitle } from "../../_start/layout/core";
import { KTSVG } from "../../_start/helpers";
import { LocationState } from "../../_start/layout/core";
import { useLocation } from "react-router-dom";
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import api from "../api";
import moment from "moment";
import "moment/locale/fr";
import LoaderSpinnerBilan from "../modules/fonctions/LoaderSpinnerBilan";
import Noresults from "../modules/fonctions/Noresults";
import { Link } from "react-router-dom";
import { Worker ,Viewer} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import type { SetRenderRange, VisiblePagesRange } from '@react-pdf-viewer/core';
import TitlePage from "../modules/fonctions/TitlePage";

export const AffichePdf = () => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const location = useLocation();

    const PdfNameAffiche = (location.state as LocationState)?.pdfaffiche;

    console.log(PdfNameAffiche);
    const docs = `${PdfNameAffiche}`;


 const newplugin = defaultLayoutPlugin()
 return (
     <div>
     <TitlePage title="Consultation du Document" />
    <div
        className="rpv-core__viewer "
        style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}
    >

        <div
            style={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                padding: '4px',
            }}
        >
            <Toolbar>
                {(props: ToolbarSlot) => {
                    const {
                        CurrentPageInput,
                        Download,
                        EnterFullScreen,
                        GoToNextPage,
                        GoToPreviousPage,
                        NumberOfPages,
                        Print,
                        ShowSearchPopover,
                        Zoom,
                        ZoomIn,
                        ZoomOut,
                    } = props;
                    return (
                        <>

                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Zoom />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomIn />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '0px 2px', width: '4rem' }}>
                                <CurrentPageInput />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                / <NumberOfPages />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToNextPage />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <EnterFullScreen />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Download />
                            </div>

                        </>
                    );
                }}
            </Toolbar>
        </div>
                    <div
                        className="container"
                     
                    >

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <Viewer fileUrl={docs} plugins={[toolbarPluginInstance]}/>
                        </Worker>
                   </div>

                   </div>

     </div>
              

    );
};

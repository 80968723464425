import React, { useState, useEffect, useCallback } from "react";
import { KTSVG } from "../../_start/helpers";
import { useLocation, Link, useParams } from "react-router-dom";
import api from "../api";
import moment from "moment";
import "moment/locale/fr";
import LoaderSpinner from "../modules/fonctions/LoaderSpinner";
import CustomIndicator from "../modules/fonctions/CustomIndicator";
import TitlePage from "../modules/fonctions/TitlePage";

interface DM {
  token: string;
}
interface LaboDM {
  sejour_id: number;
  num_admission: string;
  prescription_labo_global_id: number;
  prescription_labo_id: number;
  medecin_prescripteur: string;
  laboratoire: string;
  date: string;
  clinique: string;
  token_consolidation_sejour: string;
  token_his: string;
  file: string;
  details: Array<{

    identifiant_analyse: string;
    libelle: string;
    date: string;
  }>;

}
interface AdmissionDM {
  global_id_sejour: number;
  num_admission: string;
  entree_reelle: string;
  sortie_reelle: string;
  clinique: string;
  sejour_id: number;
  dossier_pere: string | null;
  motif_dmission: string;
  type_admission: string;
  particient_first_name: string;
  particient_last_name: string;
  services_rooms: any[];
}

interface OperationsDM {
  num_admission: string;
  acte: null | string;
  operateur: string;
  b_salle_op: string;
  sercice: string;
  sercice_name: string;
  chambre_name: string;
  lit_name: null | string;
  date_operation: Date;
}

interface RadiologieDM {
  num_admission: string;
  type_examen: string;
  token_consolidation_sejour: string;
  medecin_radiologue: null | string;
  date: null | Date;
  fileCompteRendu: string
}

interface OrdonancesDM {
  date_creation: Date;
  observation: null | string;
  global_id: number;
  order_item: Array<{
    autre: string;
    name: string;
  }>;
}



export const DocierMedical: React.FC = () => {
  const { token } = useParams<DM>();


  const [datalabo, setdatalabo] = useState<LaboDM[]>([]);
  const [dataoperations, setdataoperations] = useState<OperationsDM[]>([]);
  const [dataradiologie, setdataradiologie] = useState<RadiologieDM[]>([]);
  const [dataordonances, setdataordonances] = useState<OrdonancesDM[]>([]);
  const [admission, setadmission] = useState<AdmissionDM[]>([]);
  const [isLoading, setIsLoading] = useState(true);





  const datasejour = useCallback(() => {
    api.get(`/patient/info-sejours?tokenConsolidationSejour=${token}`)
      .then((response: any) => {
        if (response.data.params) {
          setadmission(response.data.params.reason_off_admission);
          setdatalabo(response.data.params.laboratory);
          setdataoperations(response.data.params.operations);
          setdataradiologie(response.data.params.radiology);
          setdataordonances(response.data.params.ordonances);
          setIsLoading(false);
        } else if (response.status === 500) {
          console.log('An error server.');
        } else {
          alert('An error occurred.');
        }
      });

  }, []);

  const downloadfile = (token_consolidation_sejour: string) => {
    const token = sessionStorage.getItem('token');
    const formData = new URLSearchParams();
    formData.append('tokenConsolidationSejour', token_consolidation_sejour);

    api.get(`/patient/compte-rendu-radiologie?${formData.toString()}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          // setfile(response.data.params.url);

          // setIsLoading(false);
          const downloadUrl = response.data.params.url;

          // Trigger the download or redirection
          window.location.href = downloadUrl;
          // console.log(file);
        }
      });

  }

  useEffect(() => {
    datasejour();
  }, [datasejour]);


  return (
    <div>

      {isLoading ? <LoaderSpinner />
        : (
          <div className="row g-5">

            {
              admission
                .map((admission, index, array) => (
                  <div key={index}>
                    <TitlePage  title={`N° dossier : ${admission.num_admission}`} />
                    <div className="col-lg-12 ">
                      <div className="card card-custom card-stretch-100 shadow mb-5 gradient">
                        <div className="card-body">
                          <span className="text-white  fw-bolder  fs-5">{admission.clinique}</span><br />
                          <span className="text-white  fw-bold  fs-7">{admission.type_admission} : {admission.motif_dmission}</span><br />
                          <span className="text-white fw-bold fs-7 ">
                            Du {moment(admission.entree_reelle, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")} Au {moment(admission.sortie_reelle, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}
                          </span>
                          <br />
                          <span className="text-white fw-bold fs-7">
                            Médecin traitant(e): <span className="text-white fw-bold">Dr {admission.particient_first_name} {admission.particient_last_name}</span>
                            {/* Médecin traitant(e): <span className="text-white fw-bold">Dr {admission.particient_first_name} {admission.particient_last_name}</span> */}
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>
                ))
            }


            <div className="col-lg-12">
              <div className="accordion " id="kt_accordion_1">
                {/* labo */}
                {datalabo.length > 0 && (
                  <div className="accordion-item mb-5 " style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
                    <h2 className="accordion-header " id="kt_accordion_1_header_1"
                      style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
                    >
                      <button
                        className="accordion-button fs-4 fw-bold collapsed gradient text-white  fw-bolder"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_1_body_1"
                        aria-expanded="false"
                        aria-controls="kt_accordion_1_body_1"
                        style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
                      >
                        <h3 className="card-title symbol symbol-40px">
                          <span className="symbol-label bg-light-success">
                            <i className="fas fa-microscope fs-1x text-info" style={{ fontSize: '18px' }}></i>
                          </span>
                        </h3>

                        <span className="text-white  fw-bolder" style={{ marginLeft: '10px' }}>Laboratoire <span className="fs-6 text-white  fw-bold">({datalabo.length})</span></span>

                      </button>
                    </h2>
                    <div
                      id="kt_accordion_1_body_1"
                      className="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_1_header_1"
                      data-bs-parent="#kt_accordion_1"
                      style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
                    >
                      <div className="accordion-body">
                        {
                          datalabo
                            // .slice(0, showAllLabo ? laboCount : 2)
                            .map((datalabo, index, array) => (
                              <div key={index}>
                                <div className="container  p-0 ">
                                  <div className="row d-flex flex-row justify-content-center align-items-center  p-0">
                                    <div className="col-lg-9 d-flex flex-column mb-2 ">
                                      <span className="text-black  fw-bold  fs-5 ">Le {moment(datalabo.date, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</span>
                                      <span className="text-muted  fw-bold  fs-7">{datalabo.laboratoire}</span>
                                      <div className="details">
                                        {datalabo.details.map((detail, detailIndex) => (
                                          <React.Fragment key={detailIndex}>
                                            {detailIndex > 0 ? <span className="text-muted  fw-bold  fs-9"> - </span> : null}
                                            <span className="text-muted fw-bold fs-7">{detail.identifiant_analyse}</span>
                                          </React.Fragment>
                                        ))}
                                      </div>
                                    </div>


                                    <div className="col-lg-3">
                                      {datalabo.file ? (

                                        <Link
                                          to={{
                                            pathname: `/AffichePdf`,
                                            state: { pdfaffiche: `${process.env.REACT_APP_DOMAINNAME}${datalabo.file}` },
                                          }}
                                        >
                                          <CustomIndicator
                                            path="/media/icons/duotone/Files/DownloadedFile.svg"
                                            label="Voir le document"
                                          />
                                        </Link>
                                      ) : null}
                                    </div>


                                  </div>
                                </div>
                                {index !== array.length - 1 && (
                                  <div className="separator my-3"></div>
                                )}
                              </div>
                            ))

                        }
                      </div>
                    </div>
                  </div>
                )}
                {/* Radio */}
                {dataradiologie.length > 0 && (
                  <div className="accordion-item mb-5" style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                    <h2 className="accordion-header" id="kt_accordion_1_header_2"
                      style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                      <button
                        className="accordion-button fs-4 fw-bold collapsed gradient text-white  fw-bolder"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_1_body_2"
                        aria-expanded="false"
                        aria-controls="kt_accordion_1_body_2"
                        style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                      >
                        <h3 className="card-title symbol symbol-40px">
                          <span className="symbol-label bg-light-success">
                            <i className="fas fa-x-ray fs-1x text-info" style={{ fontSize: '18px' }}></i>
                          </span>
                        </h3>

                        <span className="text-white  fw-bolder" style={{ marginLeft: '10px' }}>Radiologie <span className="fs-6 text-white  fw-bold">({dataradiologie.length})</span></span>

                      </button>
                    </h2>
                    <div
                      id="kt_accordion_1_body_2"
                      className="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_1_header_2"
                      data-bs-parent="#kt_accordion_1"
                      style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    >
                      <div className="accordion-body">
                        {
                          dataradiologie
                            // .slice(0, showAllRadio ? radioCount : 2)
                            .map((dataradiologie, index, array) => (
                              <div key={index}>
                                <div className="container  p-0 ">
                                  <div className="row d-flex flex-row justify-content-center align-items-center  p-0">
                                    <div className="col-lg-9 d-flex flex-column mb-2 ">
                                      <span className="text-black  fw-bold  fs-5 ">{dataradiologie.type_examen}</span>
                                      <span className="text-muted  fw-bold  fs-7">Le {moment(dataradiologie.date, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</span>
                                      <span className="text-muted fw-bold fs-7">
                                        Médecin radiologue: <span className="text-muted fw-bold">Dr {dataradiologie.medecin_radiologue}</span>
                                      </span>
                                    </div>


                                    <div className="col-lg-3">
                                      {dataradiologie.fileCompteRendu ? (

                                        <Link
                                          to={{
                                            pathname: `/AffichePdf`,
                                            state: { pdfaffiche: `${process.env.REACT_APP_DOMAINNAME}${dataradiologie.fileCompteRendu}` },
                                          }}
                                        >
                                        <CustomIndicator
                                            path="/media/icons/duotone/Files/DownloadedFile.svg"
                                            label="Voir le document"
                                          />
                                        </Link>


                                      ) : null}

                                    </div>


                                  </div>


                                </div>
                                {index !== array.length - 1 && (
                                  <div className="separator my-3"></div>
                                )}
                              </div>

                            ))
                        }
                      </div>
                    </div>
                  </div>
                )}
                {/* Operation */}
                {dataoperations.length > 0 && (
                  <div className="accordion-item mb-5" style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                    <h2 className="accordion-header" id="kt_accordion_1_header_3"
                      style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                      <button
                        className="accordion-button fs-4 fw-bold collapsed gradient text-white  fw-bolder"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_1_body_3"
                        aria-expanded="false"
                        aria-controls="kt_accordion_1_body_3"
                        style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>

                        <h3 className="card-title symbol symbol-40px">
                          <span className="symbol-label bg-light-success">
                            <i className="fas fa-procedures fs-1x text-info" style={{ fontSize: '18px' }}></i>
                          </span>
                        </h3>

                        <span className="text-white  fw-bolder" style={{ marginLeft: '10px' }}>Opération <span className="fs-6 text-white  fw-bold">({dataoperations.length})</span></span>
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_1_body_3"
                      className="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_1_header_3"
                      data-bs-parent="#kt_accordion_1"
                      style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    >
                      <div className="accordion-body">
                        {
                          dataoperations
                            // .slice(0, showAllOperations ? operationCount : 2)
                            .map((dataoperations, index, array) => (
                              <div key={index}>

                                <div className="d-flex flex-column justify-content-center align-items-between" >
                                  <span className="text-black  fw-bold  fs-5">{dataoperations.acte}</span>
                                  <span className="text-muted  fw-bold  fs-7">Le {moment(dataoperations.date_operation, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</span>

                                  <span className="text-muted  fw-bold  fs-7">Médecin opérateur : {dataoperations.operateur}</span>

                                </div>



                                {index !== array.length - 1 && ( // Vérifiez si ce n'est pas le dernier élément
                                  <div className="separator my-3"></div>
                                )}
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </div>
                )}
                {/* ordonnance */}
                {dataordonances.length > 0 && (
                  <div className="accordion-item mb-5" style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                    <h2 className="accordion-header" id="kt_accordion_1_header_4"
                      style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                      <button
                        className="accordion-button fs-4 fw-bold collapsed gradient text-white  fw-bolder"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_1_body_4"
                        aria-expanded="false"
                        aria-controls="kt_accordion_1_body_4"
                        style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>

                        <h3 className="card-title symbol symbol-40px">
                          <span className="symbol-label bg-light-success">
                            <i className="fas fa-clipboard-list fs-1x text-info" style={{ fontSize: '18px' }}></i>
                          </span>
                        </h3>

                        <span className="text-white  fw-bolder" style={{ marginLeft: '10px' }}>Ordonnances <span className="fs-6 text-white  fw-bold">({dataordonances.length})</span></span>
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_1_body_4"
                      className="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_1_header_4"
                      data-bs-parent="#kt_accordion_1"
                      style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    >
                      <div className="accordion-body">
                        {
                          dataordonances
                            // .slice(0, showAllordonnance ? ordonnanceCount : 2)
                            .map((dataordonances, index, array) => (
                              <div key={index}>

                                <div className="d-flex flex-column justify-content-center align-items-between" >
                                  <span className="text-black  fw-bold  fs-5">Le {moment(dataordonances.date_creation, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</span>
                                  <span className="text-muted  fw-bold  fs-7">{dataordonances.observation}</span>

                                  <div className="details">
                                    {dataordonances.order_item.map((order_item, order_itemIndex) => (
                                      <div key={order_itemIndex}>
                                        <span className="text-muted  fw-bold  fs-7">{order_item.autre}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>




                                {index !== array.length - 1 && ( // Vérifiez si ce n'est pas le dernier élément
                                  <div className="separator my-3"></div>
                                )}
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>



            {/* <div className="col-lg-4">
        {datalabo.length > 0 && (
          <div className="card card-custom card-stretch shadow mb-5 ">
            <div className="card-header gradient"  >
              <h3 className="card-title symbol symbol-40px">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-tint fs-1x text-info" style={{ fontSize: '18px' }}></i>
                </span>

                <span className="text-white  fw-bolder" style={{ marginLeft: '10px' }}>Sang <span className="fs-6 text-white  fw-bold">({laboCount})</span></span>


              </h3>
              <div className="card-toolbar">
              
                <span className="text-white  fw-bolder  fs-6 ">Voir tout</span>


                
              </div>
            </div>
            <div className="card-body">
              {
                isLoading ? (
                  <LoaderSpinner />
                ) :
                  datalabo.length > 0 ? (
                    datalabo
                      .slice(0, showAllLabo ? laboCount : 2)
                      .map((datalabo, index, array) => (
                        <div key={index}>
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-column justify-content-center align-items-between" >
                              <span className="text-black  fw-bold  fs-5 ">Le {moment(datalabo.date, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</span>
                              <div className="details">
                                {datalabo.details.map((detail, detailIndex) => (
                                  <React.Fragment key={detailIndex}>
                                    {detailIndex > 0 ? <span className="text-muted  fw-bold  fs-9"> - </span> : null}
                                    <span className="text-muted fw-bold fs-7">{detail.identifiant_analyse}</span>
                                  </React.Fragment>
                                ))}
                              </div>

                              <span className="text-muted  fw-bold  fs-7">{datalabo.laboratoire}</span>
                            </div>
                            <button
                              type="button"
                              className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-flip="top-end"
                            >
                              <KTSVG
                                path="/media/icons/duotone/Files/DownloadedFile.svg"
                                className="svg-icon-1"
                              />
                            </button>
                          </div>


                          {index !== array.length - 1 && ( 
                            <div className="separator my-3"></div>
                          )}
                        </div>
                      ))
                  ) : (

                    <span className="d-flex justify-content-center align-items-center indicator-progress text-primary">
                      no data

                    </span>
                  )}
            </div>

          </div>
          )}
        </div> */}

            {/* <div className="col-lg-4">
          <div className="card card-custom card-stretch shadow mb-5 ">
            <div className="card-header gradient"  >
              <h3 className="card-title symbol symbol-40px">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-file-prescription fs-1x text-info" style={{ fontSize: '18px' }}></i>
                </span>

                <span className="text-white  fw-bolder" style={{ marginLeft: '10px' }}>Préscriptions <span className="fs-6 text-white  fw-bold">({laboCount})</span></span>


              </h3>
              <div className="card-toolbar">
               
                <span className="text-white  fw-bolder  fs-6 ">Voir tout</span>


                
              </div>
            </div>
            <div className="card-body">
              {isLoading ? (
                <LoaderSpinner />
              )
                : datalabo.length > 0 ? (
                  datalabo
                    .slice(0, showAllLabo ? laboCount : 2)
                    .map((datalabo, index, array) => (
                      <div key={index}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="d-flex flex-column justify-content-center align-items-between" >
                            <span className="text-black  fw-bold  fs-5 ">Le {moment(datalabo.date, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}</span>
                            <div className="details">
                              {datalabo.details.map((detail, detailIndex) => (
                                <React.Fragment key={detailIndex}>
                                  {detailIndex > 0 ? <span className="text-muted  fw-bold  fs-9"> - </span> : null}
                                  <span className="text-muted fw-bold fs-7">{detail.identifiant_analyse}</span>
                                </React.Fragment>
                              ))}
                            </div>

                            <span className="text-muted  fw-bold  fs-7">{datalabo.laboratoire}</span>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                            data-kt-menu-flip="top-end"
                          >
                            <KTSVG
                              path="/media/icons/duotone/Files/DownloadedFile.svg"
                              className="svg-icon-1"
                            />
                          </button>
                        </div>


                        {index !== array.length - 1 && (
                          <div className="separator my-3"></div>
                        )}
                      </div>
                    ))
                    ) : (

                      <span className="d-flex justify-content-center align-items-center indicator-progress text-primary">
                        no data
                  
                      </span>
                    )
                  }
            </div>

          </div>
        </div> */}

            {/* <div className="col-lg-4">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-header">
              <h3 className="card-title symbol symbol-40px  me-5">
                <span className="symbol-label bg-light-success">
              
                  <i className="fas fa-archive fs-1x text-info" style={{ fontSize: '18px' }}></i>
                </span>
                <span className="text-info  fw-bolder" style={{ marginLeft: '10px' }}>Historique Dossier Médical <span className="fs-6 text-info  fw-bold">(4)</span></span>
              </h3>
              <div className="card-toolbar">
               
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="top-end"
                >
                  <KTSVG
                    path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
                    className="svg-icon-1"
                  />
                </button>

              
              </div>
            </div>
            <div className="card-body">
              {datalabo
                .map((datalabo, index) => (
                  <div key={index}>
                    <span className="text-info  fw-bold  fs-5">{datalabo.date}</span>
                    <div className="details">
                      {datalabo.details.map((detail, detailIndex) => (
                        <span key={detailIndex} className="text-muted fw-bold fs-6 me-3">{detail.identifiant_analyse}</span>
                      ))}
                    </div>
                    <div className="separator my-3"></div>
                  </div>
                ))}
            </div>
         
          </div>
        </div> */}
          </div>
        )}


    </div>
  )
}

export default DocierMedical;
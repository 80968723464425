/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem } from "./MenuItem";
import { KTSVG } from "../../../helpers";

export function MenuInner() {

  const handlesignout = () => {
    sessionStorage.clear();
    window.location.reload();
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {/* <h3 className="fw-bolder mb-5">Dashboards</h3> */}
          {/* <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10"> */}
          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/profile/overview" title="">

              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <KTSVG
                    className="svg-icon-1 svg-icon-primary"
                    path="/media/icons/duotone/Communication/Address-card.svg"
                  />
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Mon profil</h1>

            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/sejours" title="">
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <KTSVG
                    className="svg-icon-1 svg-icon-primary"
                    path="/media/icons/duotone/General/Clipboard.svg"
                  />
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Mes séjours</h1>
            </MenuItem>
          </div>
          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/Bilan" title="" >
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-microscope fs-1x text-primary" style={{ fontSize: '18px' }}></i>
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Mes bilans</h1>
            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/Operation" title="">
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-procedures fs-1x text-primary" style={{ fontSize: '18px' }}></i>
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Mes Opérations</h1>
            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/Ordonnance" title="">
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-clipboard-list fs-1x text-primary" style={{ fontSize: '18px' }}></i>
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Mes Ordonnances</h1>
            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/Radio" title="" >
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-x-ray fs-1x text-primary" style={{ fontSize: '18px' }}></i>
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Mes examens radio</h1>
            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/akdital_proche" title="" >
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <KTSVG
                    className="svg-icon-1 svg-icon-primary"
                    path="/media/icons/duotone/Map/Marker1.svg"
                  />
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">AKDITAL la plus proche</h1>
            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/assistance" title="" >
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-headset fs-1x text-primary" style={{ fontSize: '18px' }}></i>
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder"> Assistance</h1>
            </MenuItem>
          </div>


          <div onClick={handlesignout} className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/logout" title="">
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <KTSVG
                    className="svg-icon-1 svg-icon-primary"
                    path="/media/icons/duotone/Navigation/Sign-out.svg"
                  />
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Se déconnecter</h1>
            </MenuItem>
          </div>


          {/* <li className="menu-item">
              <MenuItem to="/Proche" title="Mes proches" />
            </li> */}



          {/* <li className="menu-item">
              <MenuItem to="/RDV" title="Rendez-vous" />
            </li>
            */}
          {/* <li className="menu-item">
              <MenuItem to="/commfinnance" title="Communication Financière" />
            </li>  */}
          {/* <li className="menu-item">
              <MenuItem to="/News" title="Actualités" />
            </li> */}
          {/* <li className="menu-item">
              <MenuItem to="/Reclamation" title="Réclamations" />
            </li>
            */}

          {/* </ul> */}
        </div>
        {/* <div className="col-sm-4">
          <h3 className="fw-bolder mb-5">Apps</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/chat" title="Chat" free={true} />
            </li>
           
            <li className="menu-item">
              <MenuItem to="/shop/shop-1" title="Shop 1" free={true} />
            </li>
            <li className="menu-item">
              <MenuItem to="/shop/shop-2" title="Shop 2" free={true} />
            </li>
            <li className="menu-item">
              <MenuItem to="/shop/product/1" title="Shop Product" free={true} />
            </li>
          </ul>
        </div>
        <div className="col-sm-4">
          <h3 className="fw-bolder mb-5">General</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/general/faq" title="FAQ" />
            </li>
            <li className="menu-item">
              <MenuItem to="/general/pricing" title="Pricing" />
            </li>
            <li className="menu-item">
              <MenuItem to="/general/invoice" title="Invoice" />
            </li>
            <li className="menu-item">
              <MenuItem to="/auth/login" title="Login" />
            </li>
            <li className="menu-item">
              <MenuItem to="/general/wizard" title="Wizard" free={true} />
            </li>
            <li className="menu-item">
              <MenuItem to="/error/404" title="Error" free={true} />
            </li>
          </ul>
        </div> */}
      </div >
      {/* <div className="row">
        <div className="col-sm-4">
          <h3 className="fw-bolder mb-5">Profile</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <Link className="menu-link ps-0 py-2" to="/profile/overview">
                Overview
              </Link>
            </li>
            <li className="menu-item">
              <a href="#" className="menu-link ps-0 py-2" data-kt-page="pro">
                Account
                <span className="badge badge-pro badge-light-danger fw-bold fs-9 px-2 py-1 ms-1">
                  Pro
                </span>
              </a>
            </li>
            <li className="menu-item">
              <a href="#" className="menu-link ps-0 py-2" data-kt-page="pro">
                Settings
                <span className="badge badge-pro badge-light-danger fw-bold fs-9 px-2 py-1 ms-1">
                  Pro
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-sm-4">
          <h3 className="fw-bolder mb-5">Resources</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <Link className="menu-link ps-0 py-2" to="/docs/getting-started">
                Documentation
              </Link>
            </li>
            <li className="menu-item">
              <a className="menu-link ps-0 py-2" href="#" data-kt-page="pro">
                Layout Builder
                <span className="badge badge-pro badge-light-danger fw-bold fs-9 px-2 py-1 ms-1">
                  Pro
                </span>
              </a>
            </li>
            <li className="menu-item">
              <Link className="menu-link ps-0 py-2" to="/docs/changelog">
                Changelog
                <span className="badge badge-changelog badge-light-danger bg-hover-danger text-hover-white fw-bold fs-9 px-2 ms-2">
                  v1.0.0
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  IThemeConfig,
  useTheme,
  getConfig,
} from "../../../../_start/layout/core";
import { StartDashboardPage } from "./StartDashboardPage";

const defaultPageConfig = getConfig();
const dashboardPageConfig: Partial<IThemeConfig> = {
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: false,
  },
};

export function StartDashboardWrapper() {
  const { setTheme } = useTheme();
  // Refresh UI after config updates
  // useEffect(() => {
  //   setTheme(dashboardPageConfig);
  //   return () => {
  //     setTheme(defaultPageConfig);
  //   };
  // }, []);

  return <StartDashboardPage />;
}

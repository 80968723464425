import React from "react";
import { PageTitle } from '../../_start/layout/core';


export function Rdv() {

    return (
        <div>
            <PageTitle>
                Rendez vous
            </PageTitle>
            <iframe
                className=""
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="100%"
                height="500"
                src="https://nabady.ma/">
            </iframe>
        </div>
    );
}

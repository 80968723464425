import React from "react";
import { PageTitle } from '../../_start/layout/core';


export function News() {

    return (
        <div>
            <PageTitle>
                Actualités
            </PageTitle>
           
        </div>
    );
}

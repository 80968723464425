/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem } from "./MenuItem";
import { KTSVG } from "../../../helpers";

export function MenuInner2() {

  const handlesignout = () => {
    sessionStorage.clear();
    window.location.reload();
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/auth/login" title="">
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <KTSVG
                    className="svg-icon-1 svg-icon-primary"
                    path="/media/icons/duotone/Communication/Address-card.svg"
                  />
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">Espace patient</h1>
            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/assistance" title="" >
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <i className="fas fa-headset fs-1x text-primary" style={{ fontSize: '18px' }}></i>
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder"> Assistance</h1>
            </MenuItem>
          </div>

          <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
            <MenuItem to="/akdital_proche" title="" >
              <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-light-success">
                  <KTSVG
                    className="svg-icon-1 svg-icon-primary"
                    path="/media/icons/duotone/Map/Marker1.svg"
                  />
                </span>
              </h3>
              <h1 className="fs-5 fw-bolder">AKDITAL la plus proche</h1>
            </MenuItem>
          </div>

        </div>
      </div >
    </>
  );
}

import React,{useState,useEffect} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { MasterLayout2 } from "../_start/layout/MasterLayout2";
import { Logout } from "./modules/auth/Logout";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { PublicRoutes } from "./routing/PublicRoutes";


type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const [isAuthenticated] = useState(!!sessionStorage.getItem('token'));
    useEffect(() => {
        // Votre script Capacitor
        import('@capacitor/app').then(({ App }) => {
            App.addListener('backButton', ({ canGoBack }) => {
                console.log(canGoBack);
                if (canGoBack) {
                    window.history.back();
                } else {
                    App.exitApp();
                }
            });
        });
    }, []);

  return (
    <BrowserRouter basename={basename}>
      <ThemeProvider>
        <Switch>
          <Route path="/logout" component={Logout} />
          {!isAuthenticated ? (
            <Route>
              <PublicRoutes />
            </Route>
          ) : (
            <>
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            </>
          )}
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export { App };

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { toAbsoluteUrl } from "../../../helpers";

type Props = {
    className: string;
};

const StatsWidget4: React.FC<Props> = ({ className = "" }) => {
    return (
      <>
        <div id="kt_carousel_1_carousel"
             className="carousel carousel-custom slide "
             data-bs-ride="carousel"
             data-bs-interval="2800"
        >
            <div className="carousel-inner ">

                <div className="carousel-item active text-center">
                    <div className={`card ${className}`}
                         style={{
                             height: "600px",
                             backgroundImage: `url('${toAbsoluteUrl(
                                 "/media/imagescliniques/slider_1.jpg"
                             )}')`,
                             backgroundSize: "cover"
                         }}>
                    </div>
                </div>
                <div className="carousel-item  text-center">
                    <div className={`card ${className}`}
                         style={{
                             height: "600px",
                             backgroundImage: `url('${toAbsoluteUrl(
                                 "/media/imagescliniques/slider_2.jpg"
                             )}')`,
                             backgroundSize: "cover"
                         }}>
                    </div>
                </div>
                <div className="carousel-item  text-center">
                    <div className={`card ${className}`}
                         style={{
                             height: "600px",
                             backgroundImage: `url('${toAbsoluteUrl(
                                 "/media/imagescliniques/slider_3.jpg"
                             )}')`,
                             backgroundSize: "cover"
                         }}>
                    </div>
                </div>



            </div>
            <div className="d-flex align-items-center justify-content-center flex-wrap">
                <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                    <li
                        data-bs-target="#kt_carousel_1_carousel"
                        data-bs-slide-to="0"
                        className="ms-1 active"
                    ></li>
                    <li
                        data-bs-target="#kt_carousel_1_carousel"
                        data-bs-slide-to="1"
                        className="ms-1"
                    ></li>
                    <li
                        data-bs-target="#kt_carousel_1_carousel"
                        data-bs-slide-to="2"
                        className="ms-1"
                    ></li>
                </ol>
            </div>
        </div>


    {/*  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                    className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img src={toAbsoluteUrl("/media/imagescliniques/slider1.png")} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
                <img src={toAbsoluteUrl("/media/imagescliniques/slider2.png")} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
                <img src={toAbsoluteUrl("/media/imagescliniques/slider3.png")} className="d-block w-100" alt="..."/>

            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
    */}
      </>

    );
};

export { StatsWidget4 };

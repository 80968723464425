import React, { useEffect } from "react";
import { Redirect, Route, Switch} from "react-router-dom";
import { Registration } from "./components/Registration";
import { Login } from "./components/Login";
import { Authcode } from "./components/Authcode";
import { toAbsoluteUrl } from "../../../_start/helpers";
import { ForgotPassword } from "./components/ForgotPassword";
import { Newpass } from "./components/Newpass";
// import i18n from "../../i18n";

export function AuthPage() {
  // const [english, setenglish] = useState('en')
  // const [frensh, sefrensh] = useState('fr')
  // const [arabic, setarabic] = useState('ar')
  // const [selected, setselected] = useState('')


  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid "
    style={{
      backgroundImage: `url('${toAbsoluteUrl("/media/illustrations/auth-background.svg")}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}>

      {/* Aside */}
      <div className="d-flex flex-column flex-lg-row-auto  w-xl-600px position-xl-relative shadow mobile-tablet gradient"
      
       >
        {/* Wrapper */}
        <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
          {/* Header */}
          <div className="d-flex flex-row-fluid flex-column text-center logoauth  p-5 p-lg-10 pt-lg-20">
            {/* Logo */}
            <a href="/home" className="py-2 py-lg-20">
              <img alt="Logo" src={toAbsoluteUrl("/media/logos/akdital_logo.png")} className="h-50px h-lg-70px" />
            </a>
            {/* Title */}
            <h1 className="d-none d-lg-block fw-bold text-white fs-1qx ">
            Votre santé, notre priorité
            </h1>
          
           
          </div>
          {/* Illustration */}
          <div
            className="d-none d-lg-block d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-center min-h-100px min-h-lg-300px"
          style={{  
            backgroundImage: `url('${toAbsoluteUrl(
            "/media/illustrations/illustration_home.svg"
          )}')`, 
        }}
          >
          </div>
        </div>
      </div>
      {/* Aside */}

      <div className="d-flex flex-column flex-lg-row-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid "
       
        >

          <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/registration" component={Registration} />
            <Route path="/auth/forgot-password" component={ForgotPassword} />
            <Route path="/auth/auth-code" component={Authcode} />
            <Route path="/auth/new-pass" component={Newpass} />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Redirect to="/auth/login" />
          </Switch>
        </div>


      </div>
    </div>


  );

  
}

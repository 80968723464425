/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { getProfileImage } from "../../../../app/modules/profile/ProfileUtils";
import { toAbsoluteUrl } from "../../../helpers";

type Props = {
  className: string;
  innerPadding?: string;
};

const FeedsWidget2: React.FC<Props> = ({ className, innerPadding = "", }) => {
  const [data] = useState(sessionStorage.getItem('userdata'));

  const sexe = data ? JSON.parse(data).sexe : '';
  const profileImage = getProfileImage(sexe);


  // const calculateAge = (birthdate: string) => {
  //   const birthDate = new Date(birthdate);
  //   const today = new Date();
  //   let age = today.getFullYear() - birthDate.getFullYear();
  //   if (
  //     today.getMonth() < birthDate.getMonth() ||
  //     (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
  //   ) {
  //     age--;
  //   }

  //   return age;
  // };



  return (

    <div className={`card ${className}`}
    //  style={{
    //   background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), linear-gradient(81.93deg, #1EA7F5 0%, #29DDFF 100%)'
    // }}
    >
      <div className="card-header gradient" style={{height : '120px'}}>
      </div>
      {/* begin::Body */}
      <div className={`card-body pb-0 ${innerPadding} `}  style={{
            position: 'relative', 
            top: -130,
            left: 0,
            right: 0,
            height: '150px',
        }}>
        {/* begin::Wrapper */}
        <div className="d-flex flex-column h-80 ">

          {/* begin::Chart */}
          <div className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center">
            <div className="position-absolute">
              <div className="symbol symbol-circle symbol-150px overflow-hidden d-flex flex-center z-index-1"
               style={{
                border: '8px solid white', // Ajoutez cette ligne pour définir une bordure blanche de 120 pixels
              }}>
                <div className="symbol-label"
                  style={{
                    backgroundImage: `url('${profileImage}')`,
                  }}></div>
                  
              </div>
            </div>
            <div

              id="kt_user_chart"
              style={{ height: "200px" }}
            ></div>
          </div>
          {/* end::Chart */}
          {/* begin::Items */}
          <div className="mb-5">
            {/* begin::Title */}
            <div className="text-center">
              {/* begin::Username */}
              <h3 className="fw-bolder text-muted fs-2">
                {data ? `${JSON.parse(data).nom} ${JSON.parse(data).prenom}` : "undefined"}
              </h3>
              {/* end::Username */}

              {/* end::Action */}
              <span className="fw-bolder text-muted fs-6  rounded ">
                {data ? JSON.parse(data).cin : "undefdddined"}
              </span>
              {/* begin::Action */}
            </div>
            {/* end::Title */}


          </div>
          {/* end::Items */}


        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Body */}
      <div className="card-footer ">
        <ul className="nav nav-tabs nav-line-tabs  fs-6">
          <li className="nav-item">
            <a
              className="nav-link active  fw-bold"
              data-bs-toggle="tab"
              href="#kt_tab_pane_1"
            >
              Mes informations
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link  fw-bold"
              data-bs-toggle="tab"
              href="#kt_tab_pane_2"
            >
              {/* Connexion et sécurité */}
              Paramètres
            </a>
          </li>
        </ul>
      </div>
    </div>


  );


};

export { FeedsWidget2 };
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../_start/layout/core";
import api from "../api";
import { toAbsoluteUrl } from "../../_start/helpers";
import { KTSVG } from "../../_start/helpers";
import Noresults from "../modules/fonctions/Noresults";
import { datacliniques } from "./DataClinique";
import TitlePage from "../modules/fonctions/TitlePage";
import { useHistory } from "react-router-dom";



interface Clinique {
  clinic_name: string;
  adresse: string;
  cp: null | string;
  ville: string;
  tel: string;
  web: string;
  gps_degres_decimaux?: string;
  img: string,
  img2: string
}


export function Assistance() {
  let history = useHistory()
  const [assistance, setassistance] = useState<Clinique[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setassistance(datacliniques as Clinique[]);
  }, []);



  // const assisstance = () => {
  //   api.post('/patient/localisation-clinics')
  //     .then((response: any) => {
  //       if (response.data.params) {
  //         setassistance(response.data.params);
  //         setIsLoading(false);
  //       } else if (response.status === 500) {
  //         // console.log('An error server.');
  //       } else {
  //         alert('An error occurred.');
  //       }
  //     });

  // }


  return (
    <div>
     <TitlePage title="Assistance" />

      {/*begin::Search Input*/}
      <div className="d-flex flex-column mb-10">
        {/*begin::Form*/}
        <form>
          <div
            className="input-group input-group-solid"
            id="kt_chat_aside_search"
          >
            <span className="input-group-text bg-white" id="basic-addon1">
              <KTSVG
                className="svg-icon-1 svg-icon-dark"
                path="/media/icons/duotone/General/Search.svg"
              />
            </span>
            <input
              type="text"
              className="form-control ps-0 py-4 h-auto bg-white"
              placeholder="chercher un clique"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </form>
        {/*end::Form*/}
      </div>
      {/*end::Search Input*/}

      <div className="row g-5">
        {assistance
          .filter(
            (clinique) =>
              clinique.clinic_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              clinique.ville.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((clinique, index) => (
            <div key={index} className="col-lg-4">
              <div className="card card-custom shadow">
                <div className="card-body pt-0 pb-0  ">

                  {/* begin::Tap pane */}

                  {/* <div className="d-flex flex-row justify-content-start align-items-center cursor-pointer" style={{ height: '120px' }} onClick={() => {
                    window.location.href = `tel:${clinique.tel}`;
                  }}>
                  

                    <div className="symbol symbol-60px symbol-2by3 me-4 ">
                      <img
                        src={clinique.img}
                        alt=""
                        className="mw-100"
                        style={{width:"150px" , borderRadius:"0px"}}
                      />
                    </div>


                    <div className="">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6 ">
                        {clinique.clinic_name}
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        {clinique.tel}
                      </span>
                    </div>
                  </div> */}

                  <div className="d-flex flex-column justify-content-center align-items-center cursor-pointer" style={{ height: '140px' }} onClick={() => {
                    window.location.href = `tel:${clinique.tel}`;
                  }}>


                    <div className="symbol symbol-60px symbol-2by3 mb-3 ">
                      <img
                        src={clinique.img}
                        alt=""
                        className="mw-100"
                        style={{ width: "150px", borderRadius: "0px" }}
                      />
                    </div>


                    <div className="d-flex flex-column  align-items-center ">
                      <a className="text-gray-800 fw-bolder text-hover-primary text-center  fs-7" >
                        {clinique.clinic_name}
                      </a>
                      <span className="text-muted fw-bold fs-7 d-block mt-1">
                        {clinique.tel}
                      </span>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          ))}
      </div>

      {assistance.length > 0 &&
        assistance
          .filter(
            (clinique) =>
              clinique.clinic_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              clinique.ville.toLowerCase().includes(searchTerm.toLowerCase())
          ).length === 0 && <Noresults />}

    </div>

  );


}

import React, { useState, useEffect } from "react";
import { PageTitle } from '../../_start/layout/core';
import { KTSVG } from "../../_start/helpers";
import api from "../api";
import moment from "moment";
import "moment/locale/fr";
import LoaderSpinnerBilan from "../modules/fonctions/LoaderSpinnerBilan";
import Noresults from "../modules/fonctions/Noresults";
import { Link } from "react-router-dom";
import CustomIndicator from "../modules/fonctions/CustomIndicator";
import TitlePage from "../modules/fonctions/TitlePage";




interface Labo {
    token_consolidation_sejour: string;
    sejour_id: number;
    num_admission: string;
    prescription_labo_global_id: number;
    prescription_labo_id: number;
    medecin_prescripteur: string;
    laboratoire: string;
    date: string;
    clinique: string;
    token_his: string;
    file: string;
    details: Array<{

        identifiant_analyse: string;
        libelle: string;
        date: string;
    }>;
}

export function Bilan() {


    const [datalabo, setdatalabo] = useState<Labo[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentDateIndex, setCurrentDateIndex] = useState(0);
    const uniqueDates = Array.from(
        new Set(datalabo?.flatMap((entry) => new Date(entry.date).getFullYear()))
    );
    const [currentDate, setCurrentDate] = useState(uniqueDates[currentDateIndex]);
    const [filteredData, setFilteredData] = useState<GroupedParams[]>([]);

    const datasejour = () => {
        api.get('/patient/bilan-sejours-laboratory')
            .then((response) => {
                if (response.data.params) {
                    setdatalabo(response.data.params);
                    setIsLoading(false);
                } else if (response.status === 500) {
                    console.log('An error server.');
                } else {
                    alert('An error occurred.');
                }
            });

    }

    useEffect(() => {
        datasejour();
    }, []);


    useEffect(() => {
        setCurrentDate(uniqueDates[currentDateIndex]);
    }, [currentDateIndex, uniqueDates]);

    useEffect(() => {
        if (currentDate) {
            const formattedCurrentYear = currentDate;
            const filteredData = datagrouped?.filter(
                (item) => {
                    const entreeYear = new Date(item.date).getFullYear();
                    return entreeYear === formattedCurrentYear;
                }
            );
            setFilteredData(filteredData || []);
        }
    }, [currentDate, currentDateIndex, datalabo]);

    const handlePrevious = () => {
        setCurrentDateIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNext = () => {
        setCurrentDateIndex((prevIndex) => Math.min(prevIndex + 1, uniqueDates.length - 1));
    };

    const isAtBeginning = currentDateIndex === 0;
    const isAtEnd = currentDateIndex === uniqueDates.length - 1;


    interface GroupedParams {
        date: string;
        data: Labo[];
    }

    const groupObjectsByDate = (data: Labo[]): GroupedParams[] => {
        const groupedData: { [key: string]: Labo[] } = {};

        data.forEach((item) => {
            const dateKey = item.date.split(' ')[0]; // Extracting the date part

            if (groupedData[dateKey]) {
                groupedData[dateKey].push(item);
            } else {
                groupedData[dateKey] = [item];
            }
        });

        // Convert the groupedData object into an array of objects
        const resultArray: GroupedParams[] = Object.keys(groupedData).map((date) => ({
            date,
            data: groupedData[date],
        }));

        return resultArray;
    };
    console.log(groupObjectsByDate(datalabo), "the data labo ");
    const datagrouped = groupObjectsByDate(datalabo)
    console.log(filteredData, 'gffgfgfgfgfg')

    return (
        <div>
            <TitlePage title="Mes Bilans" />

            {isLoading ? (
                <LoaderSpinnerBilan />
            ) : (
                <>
                    {filteredData && filteredData.length > 0 && (
                        <div className="row g-5 mb-10">
                            <div className="col-lg-12">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <ul className="pagination">
                                        <li
                                            className={`page-item next cursor-pointer ${isAtEnd ? "disabled" : ""}`}
                                            onClick={handleNext}
                                            key="next"
                                        >
                                            <span className="page-link">
                                                <i className="previous"></i>
                                            </span>
                                        </li>

                                        <li className="page-item" key="currentYear">
                                            <span className="page-link text-white gradient2">
                                                {moment(currentDate, "YYYY-MM-DD HH:mm:ss").format("YYYY")}
                                            </span>
                                        </li>
                                        <li
                                            className={`page-item previous cursor-pointer ${isAtBeginning ? "disabled" : ""}`}
                                            onClick={handlePrevious}
                                            key="previous"
                                        >
                                            <span className="page-link">
                                                <i className="next"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row g-5">
                        <div className="accordion" id="kt_accordion_1">
                            {filteredData && filteredData.length > 0 ? (
                                datagrouped.map((datalabo, index) => (
                                    <div key={index} className="col-lg-12">
                                        <div
                                            key={`${index}_${index}`}
                                            className="accordion-item mb-5 "
                                            style={{
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <h2
                                                className="accordion-header"
                                                id={`kt_accordion_1_header_${index}_${index + 1}`}
                                                style={{
                                                    borderRadius: '8px',
                                                }}
                                            >
                                                <button
                                                    className="accordion-button fs-4 collapsed gradient text-white"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#kt_accordion_1_body_${index}_${index + 1}`}
                                                    aria-expanded="false"
                                                    aria-controls={`kt_accordion_1_body_${index}_${index + 1}`}
                                                    style={{
                                                        borderRadius: '8px',
                                                    }}
                                                >
                                                    <h3 className="card-title text-white mt-2">
                                                        Le {moment(datalabo.date, 'YYYY-MM-DD HH:mm:ss').format('D MMMM YYYY')}
                                                    </h3>
                                                </button>
                                            </h2>

                                            <div
                                                id={`kt_accordion_1_body_${index}_${index + 1}`}
                                                className="accordion-collapse collapse "
                                                aria-labelledby={`kt_accordion_1_header_${index}_${index + 1}`}
                                                data-bs-parent="#kt_accordion_1"
                                                style={{
                                                    borderRadius: '8px',
                                                }}
                                                key={index}
                                            >
                                                <div className="accordion-body card-body p-0">
                                                    {datalabo.data.map((data, detailIndex, array) => (
                                                        <div>
                                                            <div
                                                                className="row card-p d-flex flex-row justify-content-center align-items-center"
                                                                key={`rowCard_${index}_${detailIndex + 1}`}>
                                                                <div className="col-lg-8 d-flex flex-column mb-2">
                                                                    <span
                                                                        className="text-muted fw-bold fs-7">Clinique: {data.clinique}</span>
                                                                    <span
                                                                        className="text-muted fw-bold fs-7">Laboratoire: {data.laboratoire}</span>
                                                                    <span
                                                                        className="text-muted fw-bold fs-7">Médecin: {data.medecin_prescripteur}</span>
                                                                    <div className="details">
                                                                        <span
                                                                            className="text-muted fw-bold fs-7">Actes: </span>
                                                                        {data.details.map((detail, detailIndex) => (
                                                                            <React.Fragment key={detailIndex}>
                                                                                {detailIndex > 0 && <span
                                                                                    className="text-muted fw-bold fs-9"> - </span>}
                                                                                <span
                                                                                    className="text-black fw-bold fs-7">{detail.identifiant_analyse}</span>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    {data.file && (

                                                                        <Link
                                                                            to={{
                                                                                pathname: `/AffichePdf`,
                                                                                state: { pdfaffiche: `${process.env.REACT_APP_DOMAINNAME}${data.file}` },
                                                                            }}
                                                                            key={`download_${index}_${detailIndex + 1}`}
                                                                        >
                                                                            <CustomIndicator
                                                                                path="/media/icons/duotone/Files/DownloadedFile.svg"
                                                                                label="Voir le document"
                                                                            />
                                                                        </Link>

                                                                    )}

                                                                    <Link to={{ pathname: `/docier-medical/${data.token_consolidation_sejour}` }}>
                                                                        <CustomIndicator
                                                                            path="/media/icons/duotone/General/Clipboard.svg"
                                                                            label="Voir le séjour"
                                                                        />
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                            {detailIndex !== array.length - 1 && ( // Vérifiez si ce n'est pas le dernier élément
                                                                <div className="separator"></div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <Noresults key="noResults" />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

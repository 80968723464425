import React from "react"
import { toAbsoluteUrl } from "../../../_start/helpers"

interface Noresults {

}



const Noresults: React.FC<Noresults> = (props) => (
  <div className="row g-5">
    <div className="col-lg-12 ">
      <div className="card card-custom card-flush">
        <div className="card-body" style={{  
           backgroundColor : '#F1FAFF',
        }}>
          <div  >
            <div
              className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain  min-h-250px min-h-lg-250px"
              style={{
                backgroundImage: `url('${toAbsoluteUrl(
                  "/media/illustrations/no_file.svg"
                )}')`,
              }}
            > 
            </div>
           <span className="text-black fw-bold fs-3 d-flex justify-content-center mt-8">Aucun résultat ...</span>
          </div>
        </div>
      </div>

    </div>

  </div>
)

export default Noresults
import axios from 'axios';


const getTokenFromSessionStorage = () => {
    return sessionStorage.getItem('token');
  };

// Create an instance of Axios with custom configurations
const api = axios.create({
    baseURL: "https://api.sahtekfjibek.ma/mobile/myakdital-patient/v1", // Your API base URL

   // Timeout for requests (in milliseconds)
  headers: {
    'Content-Type': 'application/json',
    'Token-group': '1GV', // Add the Token-group header here
  },
});

// Request interceptor to add a token and update 'content-type' for POST requests
api.interceptors.request.use(
  (config) => {
    // Check if the request method is 'post'
    if (config.method === 'post') {
      if (config.method === 'post' && !config.url?.includes('/auth/login')) {
        
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'; // Add this line for POST requests
      }else{
        const token = getTokenFromSessionStorage();
      // Replace 'YOUR_TOKEN_HERE' with your actual token or authentication logic
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      }
      // Replace 'YOUR_TOKEN_HERE' with your actual token or authentication logic
       // Add this line for POST requests
    }
    if (config.method === 'get') {
        const token = getTokenFromSessionStorage();
      // Replace 'YOUR_TOKEN_HERE' with your actual token or authentication logic
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
       // Add this line for POST requests
    }
    else if (config.method === 'put') {
      const token = getTokenFromSessionStorage();
    // Replace 'YOUR_TOKEN_HERE' with your actual token or authentication logic
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
     // Add this line for POST requests
  }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
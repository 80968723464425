import { toAbsoluteUrl } from "../../../_start/helpers";

export const getProfileImage = (sexe: string) => {
    let profileImage: string;
    if (sexe === 'm') {
        profileImage = toAbsoluteUrl("/media/avatars/avatar_man.jpg");
    } else if (sexe === 'f') {
        profileImage = toAbsoluteUrl("/media/avatars/avatar_female.jpg");
    } else {
        profileImage = toAbsoluteUrl("/media/avatars/blank.png");
    }
    return profileImage;
  };
  
import { toAbsoluteUrl } from "../../_start/helpers";

export const datacliniques = [
    {
        clinic_name: "CLINIQUE DU BIEN ETRE BOUSKOURA",
        adresse: "Clinique du Bien-Etre Bouskoura, Bouskoura Golf City (Prestigia), Casablanca 27182",
        cp: null,
        ville: "Bouskoura",
        tel: "0522064848",
        web: "www.akditalife-bouskoura.ma",
        gps_degres_decimaux: "33.46310185721791, -7.605844285376204",
        code_etablissement: "CBB",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-15.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/15.jpg")
    },
    {
        clinic_name: "HOPITAL INTERNATIONAL DE FES",
        sous_name:"CENTRE INTERNATIONAL D'ONCOLOGIE FES",
        adresse: "Lotissement soluna 2 route d'imouzer fes",
        cp: "30000",
        ville: "Fes",
        tel: "0535724848",
        web: "www.hopital-international-fes.ma",
        gps_degres_decimaux: "33.992649853316856, -4.987669560913568",
        code_etablissement: "HIF",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-05.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/05.jpg")
    },
    //{
    //    clinic_name: "CENTRE INTERNATIONAL D ONCOLOGIE FES",
    //    adresse: "CENTRE INTERNATIONAL D ONCOLOGIE FES",
    //    cp: "30000",
    //    ville: "Fes",
    //    tel: "0535724949",
    //    web: "www.oncologie-fes.ma",
    //    gps_degres_decimaux: "33.992649853316856, -4.987669560913568",
    //    code_etablissement: "COF",
    //    img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-05.jpg"),
    //    img2: toAbsoluteUrl("/media/imagescliniques/clinique/05.jpg")
    //},
    {
        clinic_name: "CLINIQUE MEDICO CHIRURGICALE LONGCHAMPS",
        adresse: "4,Bd Ghandi, Casablanca 20000",
        cp: "20250",
        ville: "Casablanca",
        tel: "0522040707",
        web: "www.cliniquelongchamp.ma",
        gps_degres_decimaux: "33.58221553942994, -7.660841122117259",
        code_etablissement: "CLC",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-04.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/04.jpg")
    },
    {
        clinic_name: "CLINIQUE DES SPECIALITES DE SAFI",
        adresse: "AVENUE HASSAN 2 QUARTIER ABC VILLE : NOUVELLE SAFI",
        cp: "46000",
        ville: "Safi",
        tel: "0524620808",
        web: "www.clinique-specialite-safi.ma",
        gps_degres_decimaux: "32.29873075656917, -9.222006718634315",
        code_etablissement: "CSS",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-08.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/08.jpg")
    },
    {
        clinic_name: "CLINIQUE ATFAL",
        adresse: "50 Boulevard Abderrahim Bouabid Oasis , Casablanca ,  Maroc",
        cp: "20000",
        ville: "Casablanca",
        tel: "0522231818",
        web: null,
        gps_degres_decimaux: "33.55878716272729, -7.637594603256411",
        code_etablissement: "ATF",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-02.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/atfal.jpg")
    },
    {
        clinic_name: "CLINIQUE PANORAMA SIDI MAAROUF",
        adresse: "Bd. Abou Bakar Kadiri (Prs. rond point Al Moustakbal) Sidi Maarouf - Casablanca",
        cp: null,
        ville: "Casablanca",
        tel: "0522589696",
        web: "www.clinique-panorama.ma",
        gps_degres_decimaux: "33.52620978363936, -7.649725703257434",
        code_etablissement: "PSM",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-07.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/07.jpg")
    },
    // {
    //     clinic_name: "CLINIQUE DA VINCI",
    //     adresse: "709 Boulevard Modibo Keita, Casablanca 20000",
    //     cp: "20000",
    //     ville: "Casablanca",
    //     tel: "0522813188",
    //     web: null,
    //     gps_degres_decimaux: "33.56180728515237, -7.6173716824533555",
    //     code_etablissement: "VNC",
    //     img: toAbsoluteUrl("/media/imagescliniques/tanger.jpg")
    // },
    {
        clinic_name: "HOPITAL PRIVE EL JADIDA",
        sous_name:"CENTRE INTERNATIONAL D`ONCOLOGIE EL JADIDA",
        adresse: "Adresse : L’aérodrome, Oussat El jadida lot 32/E Im. Numéro 2",
        cp: "24000",
        ville: "El Jadida",
        tel: "0523367878",
        web: "www.hpeljadida.ma",
        gps_degres_decimaux: "33.23209846913761, -8.521950188351475",
        code_etablissement: "HPE",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-03.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/03.jpg")
    },
   // {
   //     clinic_name: "CENTRE INTERNATIONAL D`ONCOLOGIE EL JADIDA",
   //     adresse: "Adresse : L’aérodrome, Oussat El jadida lot 32/E Im. Numéro 1",
   //     cp: "24000",
   //     ville: "El Jadida",
   //     tel: "0523367979",
   //     web: "www.hpeljadida.ma",
   //     gps_degres_decimaux: "33.23209846913761, -8.521950188351475",
   //     code_etablissement: "COJ",
    //    img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-03.jpg"),
   //     img2: toAbsoluteUrl("/media/imagescliniques/clinique/03.jpg")
    //},
    {
        clinic_name: "CLINIQUE INTERNATIONALE DE MOHAMMADIA",
        adresse: "Boulevard Mohammed VI, a cote de Station Shell-Marjane , Mohammedia",
        cp: null,
        ville: "Mohammadia",
        tel: "0523289191",
        web: "www.clinique-internationale-mohammedia.ma",
        gps_degres_decimaux: "33.70955049654111, -7.348233032086986",
        code_etablissement: "CIM",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-09.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/09.jpg")
    },
    {
        clinic_name: "HOPITAL PRIVE DE SALE",
        sous_name:"CENTRE D'ONCOLOGIE PRIVE DE SALE",
        adresse: "Intersection Av. Ben Guerrir et Av. Mediouna Préfecture Sale arr. Bettana",
        cp: "11000",
        ville: "Salé",
        tel: "0537888888",
        web: "www.hopital-prive-sale.ma",
        gps_degres_decimaux: "34.03163215582236, -6.803740276252964",
        code_etablissement: "HPS",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-13.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/13.jpg")
    },
   // {
   //     clinic_name: "CENTRE D ONCOLOGIE PRIVE DE SALE",
   //     adresse: "Intersection Av. Ben Guerrir et Av. Mediouna Préfecture Sale arr. Bettana",
   //     cp: "11000",
   //     ville: "Salé",
   //     tel: "0537892222",
   //     web: "www.centre-oncologie-sale.ma",
   //     gps_degres_decimaux: "34.03163215582236, -6.803740276252964",
   //     code_etablissement: "COS",
   //     img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-13.jpg"),
   //     img2: toAbsoluteUrl("/media/imagescliniques/clinique/13.jpg")
   // },
    {
        clinic_name: "HOPITAL PRIVE CASABLANCA AIN SEBAA",
        adresse: "Adresse : 279, BD Chefchaouni Ain Sebaa",
        cp: "20000",
        ville: "Casablanca",
        tel: "0522680000",
        web: null,
        gps_degres_decimaux: "33.61265525353337, -7.5302641443005",
        code_etablissement: "HPC",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-01.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/01.jpg")
    },
    {
        clinic_name: "HOPITAL PRIVE DE TANGER",
        sous_name:"CENTRE INTERNATIONAL D'ONCOLOGIE BOUGHAZ DE TANGER",
        adresse: "Quartier Mesnana Avenue Moulay Rachid - TANGER",
        cp: "90000",
        ville: "Tanger",
        tel: "0539319898",
        web: "www.hptanger.ma",
        gps_degres_decimaux: "35.744800932086804, -5.862169490769834",
        code_etablissement: "HPT",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-10.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/10.jpg")
    },
   // {
   //     clinic_name: "CENTRE INTERNATIONAL D'ONCOLOGIE BOUGHAZ DE TANGER",
   //     adresse: "Quartier Mesnana Avenue Moulay Rachid - TANGER",
   //     cp: "90000",
   //     ville: "Tanger",
   //     tel: "0539319831",
   //     web: "www.tanger-oncologie.ma",
   //     gps_degres_decimaux: "35.744800932086804, -5.862169490769834",
   //     code_etablissement: "COT",
   //     img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-10.jpg"),
  //      img2: toAbsoluteUrl("/media/imagescliniques/clinique/10.jpg")
  //  },
    {
        clinic_name: "CENTRE INTERNATIONAL D'ONCOLOGIE CASABLANCA",
        adresse: "4, route de l'Oasis rue des Alouettes\r\nCasablanca",
        cp: "20000",
        ville: "Casablanca",
        tel: "0522778181",
        web: null,
        gps_degres_decimaux: "33.55707831016901, -7.630919047432212",
        code_etablissement: "COC",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-12.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/12.jpg")
    },
    {
        clinic_name: "HOPITAL INTERNATIONAL AGADIR",
        sous_name:"CENTRE INTERNATIONAL D'ONCOLOGIE AGADIR",
        adresse: "Sis à zone grand stade , Route n°1 (en face CHU) IDA OUTANAN",
        cp: "80000",
        ville: "Agadir",
        tel: "0528207171",
        web: "www.hiagadir.ma",
        gps_degres_decimaux: "30.41484729072883, -9.538787114950775",
        code_etablissement: "HIA",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-06.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/06.jpg")
    },
   // {
   //     clinic_name: "CENTRE INTERNATIONAL D'ONCOLOGIE AGADIR",
   //     adresse: "Sis à zone grand stade , Route n°1 (en face CHU) IDA OUTANAN",
   //     cp: "80000",
   //     ville: "Agadir",
   //     tel: "0528207171",
  //      web: "www.hiagadir.ma",
   //     gps_degres_decimaux: "30.41484729072883, -9.538787114950775",
   //     code_etablissement: "COA",
   //     img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-06.jpg"),
   //     img2: toAbsoluteUrl("/media/imagescliniques/clinique/06.jpg")
   // },
    {
        clinic_name: "CLINIQUE CASABLANCA AIN BORJA",
        adresse: "25 rue  Ifni (en face de Lydec) Aïn Borja - Casablanca",
        cp: "20000",
        ville: "Casablanca",
        tel: "0522416363",
        web: "www.cliniqueinborja.ma",
        gps_degres_decimaux: "33.58526728921571, -7.596626132090746",
        code_etablissement: "CAB",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-14.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/141.jpg")
    },
    {
        clinic_name: "Hôpital Privé Beni Mellal",
        adresse: "Villa agriculture,Bd Omar  Bno Khattab Quartier administratif - BENI MELLAL",
        cp: "23000",
        ville: "Beni Mellal",
        tel: "0523425858",
        web: "www.hp-benimellal.ma",
        gps_degres_decimaux: "32.32916036840946, -6.359122675120351",
        code_etablissement: "HPB",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-16.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/beni_mellal.jpg")
    },
    {
        clinic_name: "CLINIQUE JERRADA OASIS",
        adresse: "Bd Abderrahim Bouabid, Oasis, Casablanca",
        cp: "20000",
        ville: "Casablanca",
        tel: "0522238181",
        web: "www.cliniquejerrada.ma",
        gps_degres_decimaux: "33.556533813707674, -7.63270417989128",
        code_etablissement: "CJO",
        img: toAbsoluteUrl("/media/imagescliniques/clinique/LOGO-11.jpg"),
        img2: toAbsoluteUrl("/media/imagescliniques/clinique/jerrada.png")
    }
];

import React, { useState ,useEffect} from "react";
import { toAbsoluteUrl } from "../../../helpers";
import api from "../../../../app/api";
import {
  HeaderUserMenu,
} from "../../../partials";

export function Topbar() {
  useEffect(() => {
    handleHome();
  }, []);
  // const { config } = useTheme();
  // const [showSearchModal, setShowSearchModal] = useState(false);
  // const [showInboxComposeModal, setShowInboxComposeModal] = useState(false);
  // const [irrors, seterror] = useState("");
  const [sexe, setsexe] = useState('');

 
  const handleHome = () => {
    const token = sessionStorage.getItem('token');
    api.get('/auth_mobile/me', {
      headers: {
        Authorization: 'Bearer ' + token,
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    )
      .then((response) => {
        if (response.data.params) {
          setsexe(response.data.params.sexe);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  let profileImage: string;
  if (sexe === 'm') {
    profileImage = toAbsoluteUrl("/media/avatars/avatar_man.jpg");
  } else if (sexe === 'f') {
    profileImage = toAbsoluteUrl("/media/avatars/avatar_female.jpg");
  } else {
    // If sexe is not "m" or "f", display a default image
    profileImage = toAbsoluteUrl("/media/avatars/blank.png");
  }

  

  return (
    <>
      {/* begin::Search */}
      {/* <button
        className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
        onClick={() => setShowSearchModal(true)}
      >
        <KTSVG
          path="/media/icons/duotone/General/Search.svg"
          className="svg-icon-1 svg-icon-dark"
        />
      </button> */}
      {/* <SearchModal
        show={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
      /> */}
      {/* end::Search */}
      

      {/* begin::Message */}
      {/* <button
        className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
        onClick={() => setShowInboxComposeModal(true)}
      >
        <KTSVG
          path="/media/icons/duotone/Communication/Chat6.svg"
          className="svg-icon-1 svg-icon-dark"
        />
      </button>
      <InboxCompose
        show={showInboxComposeModal}
        handleClose={() => setShowInboxComposeModal(false)}
      /> */}
      {/* end::Message */}

      {/* begin::User */}
      <div className="ms-1 ms-lg-6">
    {/* begin::Toggle */}
    <div
      className="btn btn-icon btn-sm symbol symbol-30px"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
    >
      <img src={profileImage} alt="" />
    </div>
    <HeaderUserMenu />
    {/* end::Toggle */}
</div>

      {/* end::User */}

      {/* begin::Notifications */}
      {/* <div className="ms-1 ms-lg-6">
        <button
          className="btn btn-icon btn-sm btn-light-primary fw-bolder pulse pulse-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <span className="position-absolute fs-6">3</span>
          <span className="pulse-ring"></span>
        </button>
        <HeaderNotificationsMenu />
      </div> */}
      {/* end::Notifications */}

      {/* begin::Aside Toggler */}
      {/* {config.aside.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_aside_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )} */}
      {/* end::Aside Toggler */}

      {/* begin::Sidebar Toggler */}
      {/* {config.sidebar.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_sidebar_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )} */}
      {/* end::Sidebar Toggler */}
    </>
  );
}
